// App.js
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import { SocialLogin } from '@capgo/capacitor-social-login';
import TheWeek from './components/TheWeek';
import Home from './components/Home';
import Mini from './components/Mini';
import Sudoku from './components/Sudoku'
import Ridella from './components/Ridella'
import Clique from './components/Clique'
import Proximity from './components/Proximity'
import Feedback from './components/Feedback'
import TermsOfService from './components/TermsOfService'
import PrivacyPolicy from './components/PrivacyPolicy'
import AuthModal from './components/AuthModal';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import axios from 'axios';
import { getStoredUserId } from './components/utils/Userid.js';
import { Preferences } from '@capacitor/preferences';
import { StatusBar, Style, Animation } from '@capacitor/status-bar';
import { Device } from '@capacitor/device';
import { logError } from './components/utils/errorLogger';
import { getCurrentUser, signOut } from './components/utils/AuthUtil.js';
import { SplashScreen } from '@capacitor/splash-screen';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}

function App() {
  const [theme, setTheme] = useState('cupcake');
  const [user, setUser] = useState(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const uploadUsernameToDatabase = useCallback(async (username) => {
    console.log("uploading username")
    const { value: userid } = await Preferences.get({ key: 'userid' });
    const { value: pushToken } = await Preferences.get({ key: 'pushToken' });
    try {
        const response = await axios.post('https://v7z3x44mg5.execute-api.us-east-1.amazonaws.com/prod', {
            username: username,
            altID: userid || null,
            pushToken: pushToken || null,
            source: Capacitor.getPlatform()
        });
        console.log('username uploaded successfully:', response.data);
    } catch (error) {
        console.error('Error uploading userId:', error);
    }
    if (userid) {
      try {
        const response = await axios.post('https://v7z3x44mg5.execute-api.us-east-1.amazonaws.com/prod', {
            username: userid,
            altID: username,
            pushToken: pushToken || null,
            source: Capacitor.getPlatform()
        });
        console.log('username uploaded successfully:', response.data);
      } catch (error) {
          console.error('Error uploading userId:', error);
      }
    }
  }, []);

  const initializePushNotifications = async () => {
    try {
      const result = await PushNotifications.requestPermissions();  
      if (result.receive === 'granted') {
        try {
          await PushNotifications.register();
        } catch (regError) {
          await logError(regError, {
            stage: 'registration',
            platform: Capacitor.getPlatform(),
            action: 'initializePushNotifications'
          });
          throw regError;
        }
  
        PushNotifications.addListener('registration', async (token) => {
          await Preferences.set({ key: 'pushToken', value: token.value });
          try {
            const getUserId = async (retries = 5, delay = 1000) => {
              for (let i = 0; i < retries; i++) {
                const userid = await getStoredUserId();
                if (userid) return userid;
                await new Promise(resolve => setTimeout(resolve, delay));
              }
              return null;
            };
  
            const userid = await getUserId();
            
            if (userid) {
              try {
                await axios.post('https://v7z3x44mg5.execute-api.us-east-1.amazonaws.com/prod', {
                  username: userid,
                  pushToken: token.value,
                  source: Capacitor.getPlatform()
                });
              } catch (uploadError) {
                await logError(uploadError, {
                  stage: 'token_upload',
                  platform: Capacitor.getPlatform(),
                  userId: userid,
                  tokenReceived: true,
                  action: 'initializePushNotifications'
                });
                throw uploadError;
              }
            } else {
              const error = new Error('Failed to retrieve userId after multiple attempts');
              await logError(error, {
                stage: 'getUserId',
                platform: Capacitor.getPlatform(),
                tokenReceived: true,
                retriesExhausted: true,
                action: 'initializePushNotifications'
              });
              throw error;
            }
          } catch (tokenProcessError) {
            await logError(tokenProcessError, {
              stage: 'token_processing',
              platform: Capacitor.getPlatform(),
              action: 'initializePushNotifications'
            });
          }
        });
  
        PushNotifications.addListener('registrationError', async (error) => {
          await logError(error, {
            stage: 'registration_listener',
            platform: Capacitor.getPlatform(),
            originalError: JSON.stringify(error),
            action: 'initializePushNotifications'
          });
        });
  
        // Keep these listeners for functionality but remove console.logs
        PushNotifications.addListener('pushNotificationReceived', () => {});
        PushNotifications.addListener('pushNotificationActionPerformed', () => {});
      }
    } catch (error) {
        if (error.message !== 'Push notification permission denied') {
          await logError(error, {
            stage: 'initialization',
            platform: Capacitor.getPlatform(),
            isNativePlatform: Capacitor.isNativePlatform(),
            action: 'initializePushNotifications'
          });
        }
    }
  };
  
  const handleAuthStateChange = useCallback(async (authState) => {
    console.log("Auth state changed:", authState);
    try {
      const currentUser = await getCurrentUser();
      console.log("currentUser", currentUser)
      setUser(currentUser);
      if (currentUser && currentUser.username) {
        const { value: storedUsername } = await Preferences.get({ key: 'username' });
        uploadUsernameToDatabase(currentUser.username);
        if (storedUsername !== currentUser.username) {
          await Preferences.set({ key: 'username', value: currentUser.username });
        }
      }
    } catch (error) {
      setUser(null);
    }
  }, [uploadUsernameToDatabase]);

  useEffect(() => {
    const loadTheme = async () => {
      const { value: savedTheme } = await Preferences.get({ key: 'theme' });
      const themeToUse = savedTheme || 'cupcake';
      setTheme(themeToUse);
      document.documentElement.setAttribute('data-theme', themeToUse);
      if (Capacitor.isNativePlatform()) {
        try {
          if (themeToUse === 'dark') {
            await StatusBar.setStyle({ style: Style.Dark });
          } else {
            await StatusBar.setStyle({ style: Style.Light });
          }
        } catch (error) {
          console.error('Error during initialization:', error);
        }
        try {
          await SplashScreen.hide();
        } catch (error) {
            console.error('Error hiding splash screen:', error);
        }
      }
    };  
    loadTheme();
    handleAuthStateChange();
    if (Capacitor.isNativePlatform()) {
      initializePushNotifications();
    }
  }, [handleAuthStateChange]);

  const toggleTheme = async () => {
    const newTheme = theme === 'cupcake' ? 'dark' : 'cupcake';
    setTheme(newTheme);
    await Preferences.set({ key: 'theme', value: newTheme });
    document.documentElement.setAttribute('data-theme', newTheme);
    if (Capacitor.isNativePlatform()) {
      try {
        if (newTheme === 'dark') {
          await StatusBar.setStyle({ style: Style.Dark });
        } else {
          await StatusBar.setStyle({ style: Style.Light });
        }
      } catch (error) {
        console.error('Status bar toggle error:', error);
      }
    }
  };

  useEffect(() => {
    const initializeSocialLogin = async () => {
      try {
        await SocialLogin.initialize({
          google: {
            webClientId: '870445845698-gu25hhdh3fntg8tfe3msqm83bibbdcmf.apps.googleusercontent.com',
          },
          apple: {
            clientId: 'com.puzpop.app',
          },
        });
        console.log('Social login initialized');
      } catch (error) {
        console.error('Error initializing social login:', error);
      }
    };
    
    initializeSocialLogin();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const [statusBarHeight, setStatusBarHeight] = useState(0);

    useEffect(() => {
      const initializeStatusBar = async () => {
          if (!Capacitor.isNativePlatform()) {
              setStatusBarHeight(0);
              return;
          }

          try {
              await StatusBar.show({ animation: Animation.Fade });
              const statusBarInfo = await StatusBar.getInfo();
              const deviceInfo = await Device.getInfo();
              
              const isIPhone = deviceInfo.platform === 'ios';
              const isIPhoneSE = deviceInfo.model.includes('SE') || deviceInfo.name.includes('SE');

              if (!statusBarInfo.visible) {
                  setStatusBarHeight(0);
                  document.documentElement.style.setProperty('--safe-area-inset-top', '0px');
                  return;
              }

              let height = 0;
              switch (true) {
                  case !isIPhone:
                      height = 0;
                      break;
                  case isIPhoneSE:
                      height = 10;
                      break;
                  default:
                      height = window.devicePixelRatio >= 3 ? 47 : 20;
              }

              setStatusBarHeight(height);
              document.documentElement.style.setProperty(
                  '--safe-area-inset-top',
                  `${height}px`
              );
          } catch (error) {
              console.warn('Status bar initialization error:', error);
          }
      };

      initializeStatusBar();
      const handleResize = () => {
          initializeStatusBar();
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const appStyle = {
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%',
    height: `calc(100% - ${statusBarHeight}px)`,
    paddingTop: `${statusBarHeight}px`,
    paddingBottom: `10px`
  };

  return (
    <Router>
      <ScrollToTop />
      <div className={theme} style={appStyle}>
        <Routes>
          <Route path="/" element={<Home toggleTheme={toggleTheme} theme={theme} user={user} signOut={handleSignOut}  openAuthModal={() => setIsAuthModalOpen(true)} onAuthStateChange={handleAuthStateChange} />} />
          <Route path="/theweek" element={<TheWeek />} />
          <Route path="/crossword" element={<Mini />} />
          <Route path="/sudoku" element={<Sudoku />} />
          <Route path="/ridella" element={<Ridella />} />
          <Route path="/clique" element={<Clique />} />
          <Route path="/proximity" element={<Proximity />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
        <AuthModal 
          isOpen={isAuthModalOpen} 
          onClose={() => setIsAuthModalOpen(false)} 
          onAuthStateChange={handleAuthStateChange}
        />
      </div>
    </Router>
  );
}

export default App;
