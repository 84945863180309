import { Preferences } from '@capacitor/preferences';


export const getCurrentUser = async () => {
  try {
    const { value: username } = await Preferences.get({ key: 'username' });
    if (!username) {
      return null;
    }
    return { username };
  } catch (error) {
    console.error('Error getting current user:', error);
    return null;
  }
};

export const setToken = async (tokens) => {
    try {
      const { accessToken, idToken, refreshToken } = tokens;
      
      // Validate that all required tokens are present
      if (!accessToken || !idToken || !refreshToken) {
        throw new Error('Missing required tokens');
      }
  
      // Store all tokens
      await Promise.all([
        Preferences.set({ key: 'accessToken', value: accessToken }),
        Preferences.set({ key: 'idToken', value: idToken }),
        Preferences.set({ key: 'refreshToken', value: refreshToken })
      ]);
    } catch (error) {
      console.error('Error setting tokens:', error);
      throw error;
    }
};

export const signOut = async () => {
    try {
        await Preferences.remove({ key: 'accessToken' });
        await Preferences.remove({ key: 'idToken' });
        await Preferences.remove({ key: 'refreshToken' });
        await Preferences.remove({ key: 'username' });
      } catch (error) {
        console.error('Error during sign out:', error);
        throw error;
      }
};

export const isAuthenticated = async () => {
    try {
        const { value: accessToken } = await Preferences.get({ key: 'accessToken' });
        return accessToken ? true : false;
      } catch (error) {
        console.error('Error checking authentication status:', error);
        return false;
      }
};

export const getTokens = async () => {
    try {
      const [
        { value: accessToken },
        { value: idToken },
        { value: refreshToken }
      ] = await Promise.all([
        Preferences.get({ key: 'accessToken' }),
        Preferences.get({ key: 'idToken' }),
        Preferences.get({ key: 'refreshToken' })
      ]);
  
      return {
        accessToken,
        idToken,
        refreshToken
      };
    } catch (error) {
      console.error('Error getting tokens:', error);
      return {
        accessToken: null,
        idToken: null,
        refreshToken: null
      };
    }
};

