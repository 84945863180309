// playHistoryUtil.js
import { Preferences } from '@capacitor/preferences';
import { updateStreak } from '../Streak';
import { getNewYorkDate } from './DateTime';
import axios from 'axios';

export const getPlayHistory = async () => {
  // Check if lastUserPlayDataPull has been run in the last week
  
  const { value: lastPullValue } = await Preferences.get({ key: 'lastUserPlayDataPull' });
  let shouldPullData = false;

  const { value: username } = await Preferences.get({ key: 'username' });

  if (username) {
    const now = Date.now();

    if (lastPullValue) {
      const lastPullTime = parseInt(lastPullValue);
      const oneWeek = 7 * 24 * 60 * 60 * 1000;

      if (now - lastPullTime > oneWeek) {
        shouldPullData = true;
      }
    } else {
      // Never pulled before
      shouldPullData = true;
    }

    if (shouldPullData) {
      console.log("shouldPullData")
      await pullUserPlayData(username); // Wait for the data to be pulled and updated
    }
  }

  const { value } = await Preferences.get({ key: "playHistory" });
  return value ? JSON.parse(value) : {};
};

const pullUserPlayData = async (username) => {
  try {
    const response = await axios.post('https://a0ym4o6arl.execute-api.us-east-1.amazonaws.com/prod', {
      username: username
    });
    const playHistoryFromDB = response.data.playHistory;

    if (playHistoryFromDB) {
      // Get existing playHistory from local storage
      const { value: localPlayHistoryString } = await Preferences.get({ key: "playHistory" });
      const localPlayHistory = localPlayHistoryString ? JSON.parse(localPlayHistoryString) : {};

      for (const date in playHistoryFromDB) {
        if (!localPlayHistory.hasOwnProperty(date)) {
          // Date does not exist in local storage, add it
          localPlayHistory[date] = playHistoryFromDB[date];
        } else {
          const localGames = localPlayHistory[date];
          const dbGames = playHistoryFromDB[date];
          // only adding dates after Nov 3 2024 when I made the change to the data storage in user table
          localPlayHistory[date] = { ...localGames, ...dbGames };
        }
      }      

      // Update CapacitorStorage.playHistory with the merged data
      await Preferences.set({
        key: 'playHistory',
        value: JSON.stringify(localPlayHistory)
      });

      // Update lastUserPlayDataPull timestamp
      const currentTime = Date.now().toString();
      await Preferences.set({
        key: 'lastUserPlayDataPull',
        value: currentTime
      });
    } else {
      console.error('No playHistory returned from the server');
    }
  } catch (error) {
    console.error('Error pulling user play data:', error);
  }
};

export const updatePlayHistory = async (date, game, status) => {
  const playHistory = await getPlayHistory();
  if (!playHistory[date]) {
    playHistory[date] = {};
  }
  playHistory[date][game] = status;
  await Preferences.set({
    key: "playHistory",
    value: JSON.stringify(playHistory)
  });

  const today = getNewYorkDate();
  if (date === today && (status === "won" || status === "completed")) {
    const { value: streakString } = await Preferences.get({ key: 'streak' });
    if (streakString) {
      const streak = JSON.parse(streakString);
      if (streak.wonOn && !streak.wonOn.includes(today)) {
        streak.wonOn.push(today);
        await Preferences.set({
          key: 'streak',
          value: JSON.stringify(streak)
        });
      }
    }
  }
  updateStreak();
};

export const getGameStatus = async (date, game) => {
  const playHistory = await getPlayHistory();
  return playHistory[date] && playHistory[date][game] ? playHistory[date][game] : null;
};