import React from 'react';
import { toZonedTime, format } from 'date-fns-tz';
import { BarChart, Bar, YAxis, XAxis, Tooltip, LabelList, ResponsiveContainer } from 'recharts';

const now = new Date();
const newYorkTime = toZonedTime(now, 'America/New_York');
const formattedDate = format(newYorkTime, 'EEEE, MMMM dd yyyy', { timeZone: 'America/New_York' });

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-base-200 p-2 rounded shadow-lg dark:text-white">
        <p className="font-semibold">{`Guesses: ${payload[0].payload.name}`}</p>
        <p>{`Count: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const CustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const yPos = y + (height / 2) + 5;
  const xPos = x + width + 10;
  return <text x={xPos} y={yPos} className="dark:text-white" fill="currentColor" fontSize={12}>{value}</text>;
};

const GlobalStatsChart = ({ globalStats, isLoading }) => {
  if (isLoading) {
    return <div className="flex justify-center items-center h-64">
      <span className="loading loading-spinner loading-lg"></span>
    </div>;
  }

  if (!globalStats) {
    return <p className="text-center">No data available.</p>;
  }

  const chartData = [
    { name: '1', count: globalStats['1Guess'] },
    { name: '2', count: globalStats['2Guesses'] },
    { name: '3', count: globalStats['3Guesses'] },
    { name: '4', count: globalStats['4Guesses'] },
    { name: 'NS', count: globalStats['didNotSolve'] }
  ];

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold text-center mb-2">Global Statistics</h2>
      <h3 className="text-lg text-center mb-4">{formattedDate}</h3>
      {chartData.length > 0 ? (
        <div className="w-full h-64 mb-4">
          <ResponsiveContainer width="100%" height="100%" className="lg:w-3/4 xl:w-2/3 mx-auto">
            <BarChart data={chartData} layout="vertical" margin={{ right: 30, left: -30 }}>
              <YAxis type="category" dataKey="name" axisLine={false} tickLine={false} tick={{ fill: 'currentColor' }} />
              <XAxis type="number" hide={true} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="count" fill="#8884d8" barSize={45}>
                <LabelList dataKey="count" content={<CustomizedLabel />}/>
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <p className="text-center">No data available.</p>
      )}
      <h3 className="text-lg text-center font-semibold">
        Total Players: {globalStats.totalUsers}
      </h3>
    </div>
  );
};

export default GlobalStatsChart;