import React from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

function Footer() {
  return (
    <div className="flex justify-center gap-4 p-4 text-sm">
      <Link to="/privacy" className="hover:text-blue-800">
        Privacy Policy
      </Link>
      <span>|</span>
      <Link to="/tos" className="hover:text-blue-800">
        Terms of Service
      </Link>
    </div>
  );
}

export default Footer;
