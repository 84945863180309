// Streak.js
import React, { useEffect, useState } from "react";
import { Preferences } from '@capacitor/preferences';
import { getNewYorkDate, getDifferenceInDays, formatToDateObj, getPrevDate } from './utils/DateTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faStar, faBrain, faSeedling } from '@awesome.me/kit-636b1434d3/icons/duotone/solid';
import { faChevronLeft, faChevronRight } from '@awesome.me/kit-636b1434d3/icons/classic/regular';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { PlayOtherGameButton } from './CompletedModal'

const isDateComplete = (dateGames) => {
  return Object.values(dateGames).some(status => 
    ['completed', 'won'].includes(status)
  );
};

const processStreak = async (playHistory) => {
  const dates = Object.keys(playHistory)
    .filter(date => date !== 'undefined' && date !== 'null')
    .sort();

  if (dates.length === 0) {
    return {
      current: 0,
      max: 0,
      lastGameWonOn: null,
      wonOn: [],
      freezeCount: 0,
      freezeUsedOn: []
    };
  }

  const { value: existingStreakString } = await Preferences.get({ key: 'streak' });
  let existingStreak = {};
  if (existingStreakString) {
    existingStreak = JSON.parse(existingStreakString);
  }

  let wonOn = [];
  if (!existingStreak.wonOn) {
    wonOn = dates.filter(date => playHistory[date] && isDateComplete(playHistory[date])).sort();
  } else {
    wonOn = existingStreak.wonOn;
  }

  const actualWonOn = wonOn.filter(date => playHistory[date] && isDateComplete(playHistory[date]));
  const freezeUsedOn = new Set(existingStreak.freezeUsedOn || []);

  let currentStreak = 0;
  let maxStreak = 0;
  let lastGameWonOn = existingStreak.lastGameWonOn || null;

  const today = getNewYorkDate();
  const yesterday = getPrevDate(today);
  let startDate = today;
  if (
    playHistory[yesterday] &&
    isDateComplete(playHistory[yesterday]) &&
    (!playHistory[today] || !isDateComplete(playHistory[today]))
  ) {
    startDate = yesterday;
  }

  // Calculate current streak
  let currentDate = startDate;
  while (true) {
    if (actualWonOn.includes(currentDate)) {
      if (lastGameWonOn === null) {
        lastGameWonOn = currentDate;
      }
      currentStreak++;
      currentDate = getPrevDate(currentDate);
    } else if (freezeUsedOn.has(currentDate)) {
      currentDate = getPrevDate(currentDate); // Skip freeze days
    } else if (currentDate === today) {
      // If today has not been played, allow the streak to continue
      currentDate = getPrevDate(currentDate);
    } else {
      break;
    }
  }

  // Calculate max streak
  let tempStreak = 0;
  for (let i = 0; i < actualWonOn.length; i++) {
    if (i === 0) {
      tempStreak = 1;
    } else {
      const dayDiff = getDifferenceInDays(
        formatToDateObj(actualWonOn[i - 1]),
        formatToDateObj(actualWonOn[i])
      );

      if (dayDiff === 1 || (dayDiff === 2 && freezeUsedOn.has(getPrevDate(actualWonOn[i])))) {
        tempStreak++;
      } else {
        tempStreak = 1;
      }
    }
    maxStreak = Math.max(maxStreak, tempStreak);
  }

  return {
    current: currentStreak,
    max: maxStreak,
    lastGameWonOn: lastGameWonOn || '',
    wonOn,
    freezeCount: existingStreak.freezeCount || 0,
    freezeUsedOn: Array.from(freezeUsedOn)
  };
};

const getActualConsecutiveDays = (playHistory) => {
  const dates = Object.keys(playHistory)
    .filter(date => date !== 'undefined' && date !== 'null')
    .sort();

  const actualWonOn = dates.filter(date => playHistory[date] && isDateComplete(playHistory[date]));

  const today = getNewYorkDate();
  const yesterday = getPrevDate(today);
  let startDate = today;
  if (
    playHistory[yesterday] && 
    isDateComplete(playHistory[yesterday]) && 
    (!playHistory[today] || !isDateComplete(playHistory[today]))
  ) {
    startDate = yesterday;
  }

  let consecutive = 0;
  let currentDate = startDate;
  while (actualWonOn.includes(currentDate)) {
    consecutive++;
    currentDate = getPrevDate(currentDate);
  }

  return consecutive;
};

export const awardStreakFreeze = async () => {
  const today = getNewYorkDate();
  const { value: streakValue } = await Preferences.get({ key: 'streak' });
  let streakData = streakValue ? JSON.parse(streakValue) : {};
  if (streakData.lastFreezeAwardDate === today || streakData.lastFreezeAwardDate === getPrevDate(today)) {
    console.log("Streak freeze cannot be awarded two days in a row.");
    return;
  }
  console.log("Streak freeze can be awarded today.");
  
  const { value: historyString } = await Preferences.get({ key: 'playHistory' });
  if (!historyString) {
    return null;
  }
  const playHistory = JSON.parse(historyString);
  const actualConsecutiveDays = getActualConsecutiveDays(playHistory);

  if (streakData.freezeCount < 2) {
    if (streakData.freezeCount === 0 && actualConsecutiveDays >= 2) {
      streakData.freezeCount = 1;
    } else if (streakData.freezeCount === 1 && actualConsecutiveDays >= 3) {
      streakData.freezeCount = 2;
    }
  }
  streakData.lastFreezeAwardDate = today;

  await Preferences.set({
    key: 'streak',
    value: JSON.stringify(streakData),
  });

  console.log("Streak freeze awarded.");
};

export const updateStreak = async (setModalState) => {
  try {
    await spendStreakFreeze(setModalState);

    const { value: historyString } = await Preferences.get({ key: 'playHistory' });
    if (!historyString) {
      return null;
    }

    const playHistory = JSON.parse(historyString);
    const streakData = await processStreak(playHistory);

    const { value } = await Preferences.get({ key: 'streak' });
    let existingData = {};
    if (value) {
      existingData = JSON.parse(value);
    }

    // Preserve lastFreezeAwardDate and other existing fields
    const updatedStreakData = {
      ...streakData,
      shownStreak: existingData.shownStreak || null,
      lastFreezeAwardDate: existingData.lastFreezeAwardDate || null, // Explicitly preserve this field
    };

    console.log(updatedStreakData);

    await Preferences.set({
      key: 'streak',
      value: JSON.stringify(updatedStreakData)
    });

    return updatedStreakData;
  } catch (error) {
    console.error('Error updating streak:', error);
    return null;
  }
};

const spendStreakFreeze = async (setModalState) => {
  const today = getNewYorkDate();
  const { value: streakValue } = await Preferences.get({ key: 'streak' });
  if (!streakValue) return;

  let streakData = JSON.parse(streakValue);
  const diff = getDifferenceInDays(formatToDateObj(streakData.lastGameWonOn), formatToDateObj(today));

  let freezeUsed = 0;
  const missedDays = []; // Track missed days

  if (diff === 2 && streakData.freezeCount >= 1) {
    const missedDay = getPrevDate(today);
    streakData.wonOn.push(missedDay);
    streakData.wonOn.sort();
    streakData.freezeCount -= 1;
    freezeUsed = 1;
    missedDays.push(missedDay);
  } else if (diff === 3 && streakData.freezeCount >= 2) {
    const missedDay1 = getPrevDate(today);
    const missedDay2 = getPrevDate(missedDay1);
    streakData.wonOn.push(missedDay1, missedDay2);
    streakData.wonOn.sort();
    streakData.freezeCount -= 2;
    freezeUsed = 2;
    missedDays.push(missedDay1, missedDay2);
  } else if (diff === 3 && streakData.freezeCount === 1) {
    const missedDay1 = getPrevDate(today);
    streakData.wonOn.push(missedDay1);
    streakData.wonOn.sort();
    streakData.freezeCount -= 1;
    freezeUsed = 1;
    missedDays.push(missedDay1);
  }

  if (freezeUsed > 0) {
    console.log("streak spent")
    streakData.freezeUsedOn = [...streakData.freezeUsedOn, ...missedDays];
    await Preferences.set({
      key: 'streak',
      value: JSON.stringify(streakData)
    });
    setModalState({ isOpen: true, trigger: "streak_freeze_used" });
  }
};

export const useStreakWonGameToday = () => {
  const [wonToday, setWonToday] = useState(false);

  useEffect(() => {
    const checkStreak = async () => {
      try {
        const { value } = await Preferences.get({ key: 'playHistory' });
        if (!value) {
          setWonToday(false);
          return;
        }
    
        const playHistory = JSON.parse(value);
        const todayString = getNewYorkDate();
        const todayGames = playHistory[todayString];
        
        if (!todayGames) {
          setWonToday(false);
          return;
        }

        const hasWon = Object.values(todayGames).some(status => 
          ['completed', 'won'].includes(status)
        );
        
        setWonToday(hasWon);
      } catch (error) {
        setWonToday(false);
      }
    };
    
    checkStreak();
  }, []);

  return wonToday;
};

export const useCurrentStreak = () => {
  const [currentStreak, setCurrentStreak] = useState(0);
  const wonToday = useStreakWonGameToday();

  useEffect(() => {
    let mounted = true;

    const getStreak = async () => {
      try {
        const { value } = await Preferences.get({ key: 'streak' });
        if (value && mounted) {
          const streakData = JSON.parse(value);
          setCurrentStreak(streakData.current);
        }
      } catch (error) {
        if (mounted) {
          setCurrentStreak(0);
        }
      }
    };

    getStreak();

    return () => {
      mounted = false;
    };
  }, [wonToday]);

  return currentStreak;
};

export const getStreakInfoToStore = async () => {
  try {
    const { value: streakString } = await Preferences.get({ key: 'streak' });
    const streakData = JSON.parse(streakString);
    const { current, freezeCount, freezeUsedOn, max } = streakData;
    return {
      current: current || 0,
      freezeCount: freezeCount || 0,
      freezeUsedOn: freezeUsedOn || [],
      max: max || 0,
    };
  } catch (error) {
    console.error('Error fetching streak info:', error);
    return { current: 0, freezeCount: 0, freezeUsedOn: [], max: 0 };
  }
};

const StreakCalendar = ({ wonOn, freezeUsedOn, firstDate, lastDate }) => {
  const datesToHighlight = new Set(wonOn);
  const freezeUsedDates = new Set(freezeUsedOn || []);
  const today = new Date();
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const minDate = firstDate ? new Date(firstDate) : null;
  const maxDate = lastDate ? new Date(lastDate) : null;

  const startOfMonth = new Date(currentYear, currentMonth, 1);
  const endOfMonth = new Date(currentYear, currentMonth + 1, 0);
  const daysInMonth = endOfMonth.getDate();

  const canGoPrev =
    minDate &&
    (currentYear > minDate.getFullYear() ||
      (currentYear === minDate.getFullYear() && currentMonth > minDate.getMonth()));
  const canGoNext =
    maxDate &&
    (currentYear < maxDate.getFullYear() ||
      (currentYear === maxDate.getFullYear() && currentMonth < maxDate.getMonth()));

  const goPrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const goNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentYear(currentYear + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  return (
    <div className="flex flex-col items-center dark:text-white mb-8">
      <div className="flex items-center justify-between mb-4">
        <button
          disabled={!canGoPrev}
          onClick={goPrevMonth}
          className="btn btn-ghost mr-12"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
        </button>
        <p className="text-md font-bold text-center">
          {startOfMonth.toLocaleString("default", { month: "long" })} {currentYear}
        </p>
        <button
          disabled={!canGoNext}
          onClick={goNextMonth}
          className="btn btn-ghost ml-12"
        >
          <FontAwesomeIcon icon={faChevronRight} className="text-lg" />
          </button>
      </div>
      <div className="grid grid-cols-7 gap-2 text-center">
        {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((d) => (
          <span key={d} className="font-bold">
            {d}
          </span>
        ))}
        {Array(startOfMonth.getDay())
          .fill(null)
          .map((_, i) => (
            <span key={`blank-${i}`} className="opacity-0">
              -
            </span>
          ))}
        {Array.from({ length: daysInMonth }, (_, i) => {
          const currentDateStr = new Date(
            currentYear,
            currentMonth,
            i + 1
          ).toISOString().split("T")[0];
          const highlight = datesToHighlight.has(currentDateStr);
          const freezeHighlight = freezeUsedDates.has(currentDateStr);
          return (
            <span
              key={i}
              className={`rounded-full w-8 h-8 flex items-center justify-center ${
                freezeHighlight
                  ? "bg-blue-500 text-white"
                  : highlight
                  ? "bg-[#FF9500] text-white"
                  : ""
              }`}
            >
              {i + 1}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export const StreakModal = ({ isOpen, onClose, trigger }) => {  
  const [streakData, setStreakData] = useState({ current: 0, max: 0, freezeCount: 0 });
  const [lastGamePlayed, setLastGamePlayed] = useState(null);
  const wonToday = useStreakWonGameToday();
  const earliestDate = streakData.wonOn && streakData.wonOn.length > 0 ? streakData.wonOn[0] : null;
  const latestDate = streakData.wonOn && streakData.wonOn.length > 0 ? streakData.wonOn[streakData.wonOn.length - 1] : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { value: streakValue } = await Preferences.get({ key: 'streak' });
        if (streakValue) {
          setStreakData(JSON.parse(streakValue));
        }

        const { value: historyValue } = await Preferences.get({ key: 'playHistory' });
        if (historyValue) {
          const history = JSON.parse(historyValue);
          const dates = Object.keys(history)
            .filter(date => date !== 'undefined' && date !== 'null')
            .sort();

          if (dates.length > 0) {
            const lastDate = dates[dates.length - 1];
            const lastGames = history[lastDate];
            const lastGame = Object.keys(lastGames)[0];
            setLastGamePlayed(lastGame);
          }
        }
      } catch (error) {
        console.error('Error fetching streak data:', error);
      }
    };

    if (isOpen) {  
      fetchData();
    }
  }, [isOpen]); 

  const renderContent = () => {
    const hasStreak = streakData.current > 0;
    const isNewRecord = streakData.current === streakData.max && streakData.current > 0;

    if (trigger === "streak_freeze_used") {
      return (
        <div className="p-6 bg-white rounded-b-lg dark:bg-black">
          <div className="flex items-center justify-center gap-2">
            <FontAwesomeIcon icon={faFire} className="text-blue-500 text-xl" />
            <p className="text-xl">Streak freeze used!</p>
          </div>
        </div>
      );
    }

    if (hasStreak && wonToday) {
      return (
        <div className="p-6 bg-white rounded-b-lg dark:bg-black">
          <div className="flex items-center justify-center gap-2">
            <FontAwesomeIcon icon={faStar} className="text-[#FF9500] text-xl" />
            <p className="text-xl">
              {isNewRecord 
                ? "Congrats on setting a new streak record!" 
                : "Congrats on reaching your latest milestone!"}
            </p>
          </div>
        </div>
      );
    }

    if (hasStreak && !wonToday) {
      return (
        <div className="p-6 bg-white rounded-b-lg dark:bg-black">
          <div className="flex items-center justify-center gap-2">
            <FontAwesomeIcon icon={faBrain} className="text-[#AC7B7D] text-xl" />
            <p className="text-xl">
              Win any game in the next 24 hours to extend your streak
            </p>
          </div>
          {lastGamePlayed && (
            <PlayOtherGameButton gameName={lastGamePlayed} />
          )}
        </div>
      );
    }

    return (
      <div className="p-6 bg-white rounded-b-lg dark:bg-black">
        <div className="flex items-center justify-center gap-2">
          <FontAwesomeIcon icon={faSeedling} className="text-xl text-[#2E8B57]" />
          <p className="text-xl">
            Win any game today to get your streak started
          </p>
        </div>
      </div>
    );
  };

  return (
    <dialog className={`modal ${isOpen ? 'modal-open' : ''}`}>
      <div className="modal-box relative p-0 overflow-hidden">
        <form method="dialog">
          <label
            htmlFor="my-modal-4"
            className="btn btn-ghost btn-lg btn-circle text-white absolute right-2 top-2"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} className="text-2xl" />
          </label>
        </form>
        <div className="flex flex-col w-full">
          <div className={`py-4 px-12 rounded-t-lg ${trigger === "streak_freeze_used" ? "bg-blue-300" : "bg-[#FF9500]"}`}>
            <div className="flex justify-between items-start">
              <div>
                <p className="text-6xl font-bold text-white">{streakData.current}</p>
                <p className="text-3xl font-bold text-white mt-1">day streak!</p>
              </div>
              <FontAwesomeIcon icon={faFire} className={`text-8xl pr-8 ${trigger === "streak_freeze_used" ? "text-blue-500" : "text-white"}`} />
            </div>
            <div className="flex justify-between items-center gap-8 mt-4">
              <p className="text-xl font-bold text-white">Record: {streakData.max} days</p>
              <p className="text-xl font-bold text-white">Streak Freezes: {streakData.freezeCount}/2</p>
            </div>
          </div>
          {renderContent()}
          <h2 className="text-2xl font-bold mb-4 pt-4 pl-8">Streak Calendar</h2>
          <StreakCalendar wonOn={streakData.wonOn} freezeUsedOn={streakData.freezeUsedOn} firstDate={earliestDate} lastDate={latestDate} />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={() => onClose()}>close</button>
      </form>
    </dialog>
  );
};

export const StreakAnimation = ({ oldStreak, newStreak }) => {
    const [showOldStreak, setShowOldStreak] = useState(true);
    
    useEffect(() => {
      const timer = setTimeout(() => {
        setShowOldStreak(false);
      }, 100);
      return () => clearTimeout(timer);
    }, []);
  
    return (
      <div className="relative h-24 w-full flex justify-center items-center">
        <div
          className={`absolute transition-all duration-500 transform pointer-events-none ${
            showOldStreak 
              ? "scale-100 translate-y-0 opacity-100" 
              : "scale-150 -translate-y-8 opacity-0"
          }`}
        >
          <span className="text-7xl font-bold text-[#FF9500]">{oldStreak}</span>
        </div>
        <div
          className={`absolute transition-all duration-500 transform pointer-events-none ${
            showOldStreak 
              ? "scale-50 translate-y-8 opacity-0" 
              : "scale-100 translate-y-0 opacity-100"
          }`}
        >
          <span className="text-7xl font-bold text-[#FF9500]">{newStreak}</span>
        </div>
      </div>
    );
};
