// Keyboard.js
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft, faRepeat } from '@awesome.me/kit-636b1434d3/icons/classic/solid';
import { Device } from '@capacitor/device';

const Keyboard = ({ onKeyPress }) => {
    const keys = [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['Z', 'X', 'C', 'V', 'B', 'N', 'M']
    ];

    const keyboardRef = useRef(null);

    const [keyboardPosition, setKeyboardPosition] = useState('bottom-8');
    useEffect(() => {
        const checkDevice = async () => {
            const info = await Device.getInfo();
            const model = info.model;

            let diagonalInches;
            if (info.platform === 'android') {
                const width = window.screen.width || window.innerWidth;
                const height = window.screen.height || window.innerHeight;        
                diagonalInches = Math.sqrt(Math.pow(width / 160, 2) + Math.pow(height / 160, 2));
            }
            const isIPhoneSE = (model?.includes('SE') ?? false) || (info.name?.includes('SE') ?? false);
            const iPhoneGeneration = model ? parseInt(model.replace('iPhone', '')) : null;
            
            switch (true) {
                case info.platform === 'android' && diagonalInches < 5:
                    setKeyboardPosition('bottom-4');
                    break;
                case isIPhoneSE:
                    setKeyboardPosition('bottom-2');
                    break;
                case (model?.includes('iPhone') ?? false) && iPhoneGeneration >= 10:
                    setKeyboardPosition('bottom-12');
                    break;
                default:
                    setKeyboardPosition('bottom-8');
            }
        };
        checkDevice();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (keyboardRef.current) {
                const viewportWidth = Math.min(window.innerWidth, 600);
                keyboardRef.current.style.width = `${viewportWidth}px`;
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleKeyPress = key => {
        onKeyPress(key);
    };

    return (
        <div ref={keyboardRef} className={`keyboard fixed ${keyboardPosition} left-1/2 transform -translate-x-1/2 w-full`} style={{ maxWidth: '600px' }}>
            {keys.map((row, index) => (
                <div
                    key={index}
                    className={`flex justify-between w-full ${index === 1 ? 'px-8' : ''}`}
                >
                    {index === 2 && (
                        <button
                            className="btn btn-s h-14 m-0.5 active:bg-gray-400"
                            onClick={() => handleKeyPress('SWITCH')}
                            style={{ flex: '1 1 0%' }} 
                        >
                            <FontAwesomeIcon icon={faRepeat} className="text-2xl" />
                        </button>
                    )}
                    {row.map((key) => (
                        <button
                            key={key}
                            className="btn btn-s h-14 m-0.5 px-2 flex-grow text-xl active:bg-gray-400"
                            onClick={() => handleKeyPress(key)}
                            style={{ flex: '1 1 0%' }}
                        >
                            {key}
                        </button>
                    ))}
                    {index === 2 && (
                        <button
                            className="btn btn-s h-14 m-0.5 flex-grow active:bg-gray-400"
                            onClick={() => handleKeyPress('BACK')}
                            style={{ flex: '1.8 1 0%' }} 
                        >
                            <FontAwesomeIcon icon={faDeleteLeft} className="text-3xl" />
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Keyboard;
