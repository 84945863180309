// AuthModal.js
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import GoogleSignInButton from './utils/GoogleSignInButton';
import AppleSignInButton from './utils/AppleSignInButton';
import { Capacitor } from '@capacitor/core';
import { Dialog } from '@capacitor/dialog';
import { Preferences } from '@capacitor/preferences';
import { isAuthenticated, setToken } from './utils/AuthUtil';
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';

const showAlert = async (message, title = 'Alert') => {
  await Dialog.alert({
    title,
    message,
  });
};

const AuthModal = ({ isOpen, onClose, onAuthStateChange }) => {
  const { authStatus } = isAuthenticated();
  const modalRef = useRef();
  const prevAuthStatusRef = useRef(authStatus);
  const isIOS = Capacitor.getPlatform() === 'ios';
  const isWeb = Capacitor.getPlatform() === 'web';
  const [activeTab, setActiveTab] = useState('signin');
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    email: ''
  });
  const [showVerification, setShowVerification] = useState(false);
  const [pendingUsername, setPendingUsername] = useState('');

  const resetModal = () => {
    setShowVerification(false);
    setActiveTab('signin');
  };

  useEffect(() => {
    if (isOpen) {
      resetModal();
    }
  }, [isOpen]);

  const poolData = {
    UserPoolId: 'us-east-1_McszXVwru',
    ClientId: 'ajnccgntbnqu53feh4lglegpj'
  };
  const userPool = new CognitoUserPool(poolData);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAuth = async (e, action) => {
    e.preventDefault();
  
    if (action === 'signin') {
      const authenticationData = {
        Username: formData.username,
        Password: formData.password,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);
  
      const userData = {
        Username: formData.username,
        Pool: userPool
      };
      const cognitoUser = new CognitoUser(userData);
  
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async function(result) {
          try {
            const tokens = {
              accessToken: result.getAccessToken().getJwtToken(),
              idToken: result.getIdToken().getJwtToken(),
              refreshToken: result.getRefreshToken().getToken()
            };
            await setToken(tokens);            
            const payload = JSON.parse(atob(tokens.idToken.split('.')[1]));            
            await Preferences.set({ 
              key: 'username', 
              value: payload['cognito:username'] || formData.username 
            });      
            if (typeof onAuthStateChange === 'function') {
              onAuthStateChange();
            }
            onClose();
          } catch (error) {
            console.error('Error during sign in:', error);
            await showAlert('Failed to complete sign in', 'Sign In Error');
          }
        },
  
        onFailure: function(err) {
          console.error('Authentication error:', err);
          showAlert(err.message || 'An error occurred during sign in', 'Sign In Error');        
        },
  
        newPasswordRequired: function(userAttributes, requiredAttributes) {
          // Handle new password required scenario
          console.log('New password required');
          showAlert('Please reset your password', 'Password Reset Required');
        }
      });
    } else if (action === 'signup') {
      // Validate passwords match
      if (formData.password !== formData.confirmPassword) {
        showAlert('Passwords do not match', 'Sign Up Error');
        return;
      }
  
      // Prepare user attributes
      const attributeList = [
        new CognitoUserAttribute({
          Name: 'email',
          Value: formData.email
        })
      ];
  
      try {
        // Sign up the user
        userPool.signUp(
          formData.username,
          formData.password,
          attributeList,
          null,
          (err, result) => {
            if (err) {
              console.error('Sign up error:', err);
              showAlert(err.message || 'An error occurred during sign up');
              return;
            }
  
            const cognitoUser = result.user;
            console.log('Sign up successful! User name is: ' + cognitoUser.getUsername());
            
            // Show success message and switch to sign in
            showAlert('Account created successfully! Please check your email for verification code.');
            setPendingUsername(formData.username);
            setShowVerification(true);
            
            // Clear form
            setFormData({
              username: '',
              password: '',
              confirmPassword: '',
              email: ''
            });
            
            if (typeof onAuthStateChange === 'function') {
              onAuthStateChange();
            }
          }
        );
      } catch (err) {
        console.error('Sign up error:', err);
        showAlert(err.message || 'An error occurred during sign up');
      }
    }
  };

  const handleAuthChange = useCallback(() => {
    if (authStatus !== prevAuthStatusRef.current) {
      if (typeof onAuthStateChange === 'function') {
        onAuthStateChange();
      }
      if (authStatus === 'authenticated') {
        onClose();
      }
    }
    prevAuthStatusRef.current = authStatus;
  }, [authStatus, onAuthStateChange, onClose]);

  const handleVerification = async (e) => {
    e.preventDefault();
  
    const userData = {
      Username: pendingUsername,
      Pool: userPool
    };
  
    const cognitoUser = new CognitoUser(userData);
  
    try {
      cognitoUser.confirmRegistration(formData.verificationCode, true, async (err, result) => {
        // Check if it's a ValidationException for the schedule creation
        // but the actual verification might have succeeded
        if (err && err.message && err.message.includes('ValidationException') && 
            err.message.includes('CreateSchedule')) {
          console.log('Schedule creation failed but verification likely succeeded');
          await showAlert('Account verified successfully! Please sign in.');
          
          // Reset everything since verification succeeded
          setFormData({
            username: '',
            password: '',
            confirmPassword: '',
            email: '',
            verificationCode: ''
          });
          setShowVerification(false);
          setActiveTab('signin');
          setPendingUsername('');
          return;
        }
        
        // Handle any other errors
        if (err) {
          console.error('Verification error:', err);
          showAlert(err.message || 'Failed to verify account', 'Verification Error');
          return;
        }
  
        // Regular success flow
        await showAlert('Account verified successfully! Please sign in.');
        setFormData({
          username: '',
          password: '',
          confirmPassword: '',
          email: '',
          verificationCode: ''
        });
        setShowVerification(false);
        setActiveTab('signin');
        setPendingUsername('');
      });
    } catch (err) {
      console.error('Verification error:', err);
      showAlert(err.message || 'An error occurred during verification');
    }
  };

  useEffect(() => {
    handleAuthChange();
  }, [handleAuthChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div ref={modalRef} className="bg-base-100 pt-8 rounded-lg overflow-y-auto relative rajdhani-font max-h-[80vh] w-[90%] max-w-md mx-auto">
        <button onClick={onClose} className="absolute top-2 right-2 text-xl">
          <FontAwesomeIcon icon={faTimes} className="text-2xl"/>
        </button>
        
        {showVerification ? (
          // Verification Form
          <div className="p-6">
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold">Verify Your Account</h2>
              <p className="text-md">Please enter the verification code sent to your email</p>
            </div>
            <form onSubmit={handleVerification} className="space-y-4">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Verification Code</span>
                </label>
                <input
                  type="text"
                  name="verificationCode"
                  value={formData.verificationCode}
                  onChange={handleInputChange}
                  className="input input-bordered w-full"
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary w-full text-white">
                Verify Account
              </button>
            </form>
          </div>
        ) : (
          // Main Auth Forms
          <div>
            <div className="text-center p-4">
              <h2 className="text-2xl font-bold mb-2">Sign up to play more!</h2>
              <p className="text-md">Create an account to track your stats and play past puzzles.<br/> It is completely free.</p>
            </div>
            <div className="px-6">
              {isIOS && <AppleSignInButton onAuthStateChange={onAuthStateChange} onClose={onClose}/>}
              {!isWeb && <GoogleSignInButton onAuthStateChange={onAuthStateChange} onClose={onClose}/>}
              {!isWeb && <div className="text-center text-sm text-gray-500 my-2">
                - or -
              </div>}
              <div className="tabs tabs-boxed mx-4">
                <button
                  className={`tab flex-1 ${activeTab === 'signin' ? 'tab-active !text-base-100 !font-bold' : ''}`}
                  onClick={() => setActiveTab('signin')}
                >
                  Sign In
                </button>
                <button
                  className={`tab flex-1 ${activeTab === 'signup' ? 'tab-active !text-base-100 !font-bold' : ''}`}
                  onClick={() => setActiveTab('signup')}
                >
                  Create Account
                </button>
              </div>
  
              <div className="p-4">
                {activeTab === 'signin' ? (
                  // Sign In Form
                  <form onSubmit={(e) => handleAuth(e, 'signin')} className="space-y-2">
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Username</span>
                      </label>
                      <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        className="input input-bordered w-full"
                        required
                      />
                    </div>
  
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Password</span>
                      </label>
                      <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className="input input-bordered w-full"
                        required
                      />
                    </div>
  
                    <button type="submit" className="btn btn-primary w-full !text-base-100 !font-bold">
                      Sign In
                    </button>
                  </form>
                ) : (
                  // Create Account Form
                  <form onSubmit={(e) => handleAuth(e, 'signup')} className="space-y-4">
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Email</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="input input-bordered w-full"
                        required
                      />
                    </div>

                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Username</span>
                      </label>
                      <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        className="input input-bordered w-full"
                        required
                      />
                    </div>
  
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Password</span>
                      </label>
                      <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className="input input-bordered w-full"
                        required
                      />
                    </div>
  
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Confirm Password</span>
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        className="input input-bordered w-full"
                        required
                      />
                    </div>
  
                    <button type="submit" className="btn btn-primary w-full !text-base-100 !font-bold">
                      Create Account
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthModal;