import axios from 'axios';
import { getStoredUserId } from './Userid';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

const ERROR_ENDPOINT = 'https://eeee5an35i.execute-api.us-east-1.amazonaws.com/prod';

export const logError = async (error, context = {}) => {
    try {
        const userId = await getStoredUserId();
        const source = ['ios', 'android'].includes(Capacitor.getPlatform()) 
            ? Capacitor.getPlatform() 
            : 'web';
        const appInfo = await App.getInfo();

        const errorPayload = {
            timestamp: new Date().toISOString(),
            userId: userId,
            source: source,
            appVersion: appInfo?.version || 'unknown',
            error: {
                message: error.message,
                stack: error.stack,
                name: error.name
            },
            context: {
                ...context,
                userAgent: navigator.userAgent,
                url: window.location.href
            }
        };

        await axios.post(ERROR_ENDPOINT, errorPayload);
    } catch (loggingError) {
        console.error('Error logging failed:', loggingError);
    }
};