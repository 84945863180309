// Mini.js
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import CrossKeyboard from './Keyboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronLeft, faChevronRight, faEllipsisVertical, faCircleInfo, faFaceDisappointed } from '@awesome.me/kit-636b1434d3/icons/classic/regular';
import CompletedModal from './CompletedModal';
import { getStoredUserId } from './utils/Userid';
import { updatePlayHistory } from './utils/playHistoryUtil';
import bigCrossword from '../assets/Big-Crossword.png';
import miniCrossword from '../assets/Mini-Crossword.png';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { loadCrosswordData } from './utils/FetchGameData';
import Timer from './Timer';
import CrosswordInfo from './CrosswordInfo';
import { useNewYorkDate } from './utils/DateTime';
import { checkAndroidAndLogEvent } from './utils/FirebaseEvent';
import RatingAlert from './RatingAlert';
import { Keyboard } from '@capacitor/keyboard';
import { getStreakInfoToStore } from './Streak';
import { useBackHandler } from '../hooks/useBackHandler';

function Mini() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const type = params.get('type') || 'crossword';
    const [selectedDimension, setSelectedDimension] = useState("row");
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [crosswordData, setCrosswordData] = useState(null);
    const [clues, setClues] = useState(null);
    const [dimensions, setDimensions] = useState(null);
    const [puzzle, setPuzzle] = useState(null);
    const [solution, setSolution] = useState(null);
    const [activeCell, setActiveCell] = useState({ row: 0, col: 0 });
    const [userAnswers, setUserAnswers] = useState([]);
    const [textColors, setTextColors] = useState([]);
    const [clueCoordinates, setClueCoordinates] = useState({});
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [confirmAction, setConfirmAction] = useState('');
    const [currentClueReferences, setCurrentClueReferences] = useState([]);
    const [circledCells, setCircledCells] = useState([]);
    const [userId, setUserId] = useState(null);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [dataLoadFailed, setDataLoadFailed] = useState(false);
    const dateParam = params.get('date');
    const [currentDate, setCurrentDate] = useState(null);
    const nyDate = useNewYorkDate();
    const [gridSize, setGridSize] = useState({ width: 0, height: 0 });
    const [completionTime, setCompletionTime] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [gameWon, setGameWon] = useState(false);
    const [lockedSquares, setLockedSquares] = useState([]);
    const [shareString, setShareString] = useState(``);
    
    useBackHandler(currentDate);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/', { state: { date: currentDate } });
    };

    const resetGameState = useCallback((newDate) => {
        setCrosswordData(null);
        setClues(null);
        setDimensions(null);
        setPuzzle(null);
        setSolution(null);
        setActiveCell({ row: 0, col: 0 });
        setUserAnswers([]);
        setTextColors([]);
        setClueCoordinates({});
        setIsCompleted(false);
        setIsModalOpen(false);
        setIsConfirmModalOpen(false);
        setConfirmAction('');
        setCurrentClueReferences([]);
        setCircledCells([]);
        setIsDataLoaded(false);
        setCurrentDate(newDate);
        setCompletionTime(null);
        setLockedSquares([]);
        setShareString(`I solved the PuzPop ${type} on ${newDate} in `);
    }, [type]);

    const handleDateChange = useCallback((newDate) => {
        resetGameState(newDate);
        navigate(`/crossword?type=${type}&date=${newDate}`);
    }, [resetGameState, navigate, type]);

    useEffect(() => {
        if (type && currentDate) {
            resetGameState(currentDate);
        }
    }, [type, currentDate, resetGameState]);

    useEffect(() => {
        if (Capacitor.getPlatform() === 'ios') {
            Keyboard.setScroll({ isDisabled: true });
            Keyboard.setAccessoryBarVisible({ isVisible: false });
        }
        return () => {
            if (Capacitor.getPlatform() === 'ios') {
                Keyboard.setScroll({ isDisabled: false });
            }
        };
    }, []);

    useEffect(() => {
        let newDate;
        if (dateParam && /^\d{4}-\d{2}-\d{2}$/.test(dateParam)) {
            newDate = dateParam;
        } else {
            newDate = nyDate;
        }
        setCurrentDate(newDate);
    }, [dateParam, nyDate]);

    const [keyboardMargin, setKeyboardMargin] = useState('mb-0');

    useEffect(() => {
        const checkDevice = async () => {
            const info = await Device.getInfo();
            const model = info.model;
            let diagonalInches;
            const width = window.screen.width || window.innerWidth;
            const height = window.screen.height || window.innerHeight;        
            diagonalInches = Math.sqrt(Math.pow(width / 160, 2) + Math.pow(height / 160, 2));

            const isIPhoneSE = (
                (model?.includes('SE') ?? false) || 
                (info.name?.includes('SE') ?? false) || 
                (info.platform === 'ios' && 
                 (window.screen.width === 320 || window.screen.width === 375) && 
                 (window.screen.height === 568 || window.screen.height === 667))
            );
            const iPhoneGeneration = model ? parseInt(model.replace('iPhone', '')) : null;
            
            switch (true) {
                case isIPhoneSE || (info.platform === 'ios' && diagonalInches < 5):
                    setKeyboardMargin('-mb-8'); // Negative margin using the '-' prefix
                    break;
                case info.platform === 'android' && diagonalInches < 5:
                    setKeyboardMargin('-mb-4');
                    break;
                case (model?.includes('iPhone') ?? false) && iPhoneGeneration >= 10:
                    setKeyboardMargin('mb-4');
                    break;
                default:
                    setKeyboardMargin('mb-0');
            }
        };
        checkDevice();
    }, []);    

    const calculateMaxHeight = (width, columns, rows) => {
      const cellSize = width / columns;
      return cellSize * rows;
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };
  
    useEffect(() => {
      if (!dimensions || !dimensions.width || !dimensions.height) {
        return;
      }
  
      const updateGridSize = () => {
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;
        const maxWidth = 600;
        const headerHeight = 40;
        const clueHeight = 60;
        const keyboardHeight = 220;
        const availableHeight = viewportHeight - headerHeight - clueHeight - keyboardHeight;
        const availableWidth = Math.min(viewportWidth, maxWidth);
  
        const maxHeight = calculateMaxHeight(availableWidth, dimensions.width, dimensions.height);
        const actualHeight = Math.min(maxHeight, availableHeight);
  
        setGridSize({
          width: availableWidth,
          height: actualHeight
        });
      };
  
      updateGridSize();
      window.addEventListener('resize', updateGridSize);
      return () => window.removeEventListener('resize', updateGridSize);
    }, [dimensions]);
      
    const sendGameData = useCallback(async (solved, revealed, game) => {
        if (!isDataLoaded || !currentDate) return;
        const streakInfo = await getStreakInfoToStore();
        try {
        const source = ['ios', 'android'].includes(Capacitor.getPlatform()) ? Capacitor.getPlatform() : 'web';
          const payload = {
            date: currentDate,
            game: game,
            userid: userId,
            solved: solved,
            revealed: revealed,
            source: source,
            streak: streakInfo
          };    
          await axios.post('https://nu5koio7l8.execute-api.us-east-1.amazonaws.com/prod', payload);
        } catch (error) {
          console.error("Error triggering Lambda:", error);
        }
    }, [currentDate, isDataLoaded, userId]);

    const getStorageKey = useCallback(() => {
        return type === 'crossword' ? 'crosswordGameStates' : 'miniGameStates';
    }, [type]);

    const saveGameState = useCallback(async () => {
        if (!isDataLoaded) return; 
        const storageKey = getStorageKey();
        const gameState = {
            userAnswers,
            textColors,
            isCompleted,
            activeCell,
            selectedDimension,
            crosswordData,
            clues,
            dimensions,
            puzzle,
            solution,
            clueCoordinates,
            circledCells,
            completionTime,
            lockedSquares,
            shareString
        };
        try {
            const { value: existingStatesString } = await Preferences.get({ key: storageKey });
            const existingStates = existingStatesString ? JSON.parse(existingStatesString) : {};
            existingStates[currentDate] = gameState;
            await Preferences.set({
                key: storageKey,
                value: JSON.stringify(existingStates)
            });
        } catch (error) {
            console.error('Error saving game state:', error);
        }
    }, [userAnswers, textColors, isCompleted, activeCell, selectedDimension, crosswordData, clues, dimensions, puzzle, solution, clueCoordinates, circledCells, getStorageKey, currentDate, isDataLoaded, completionTime, lockedSquares, shareString]);

    useEffect(() => {
        if (isDataLoaded && !isCompleted) {
            setIsTimerRunning(true);
        }
    }, [isDataLoaded, isCompleted]);

    useEffect(() => {
        let timer;
        if (isTimerRunning) {
            timer = setInterval(() => {
                setCompletionTime(prevTime => prevTime + 1);
            }, 1000);
        } else if (!isTimerRunning && completionTime !== 0) {
            clearInterval(timer);
            // Handle completion if needed
        }
        return () => clearInterval(timer);
    }, [isTimerRunning, completionTime]);

    useEffect(() => {
        if (isDataLoaded) {
            saveGameState();
        }
    }, [userAnswers, textColors, isCompleted, saveGameState, isDataLoaded]);

    useEffect(() => {
        const fetchUserId = async () => {
            const id = await getStoredUserId();
            setUserId(id);
        };
        fetchUserId();
    }, []);

    const handleConfirmAction = (action) => {
        setConfirmAction(action);
        setIsConfirmModalOpen(true);
        setDropdownOpen(false);
    };

    const executeConfirmedAction = () => {
        switch (confirmAction) {
            case 'Check Square':
                checkSquare();
                break;
            case 'Check Word':
                checkWord();
                break;
            case 'Check Puzzle':
                checkPuzzle();
                break;
            case 'Reveal Square':
                revealSquare();
                break;
            case 'Reveal Word':
                revealWord();
                break;
            case 'Reveal Puzzle':
                revealPuzzle();
                break;
            case 'Reset Puzzle':
                resetPuzzle();
                break;
            default:
                break;
        }
        setIsConfirmModalOpen(false);
    };

    const findClueCoordinates = useCallback((clueNumber, direction) => {
        for (let y = 0; y < puzzle.length; y++) {
            for (let x = 0; x < puzzle[y].length; x++) {
                if (puzzle[y][x] === clueNumber) {
                    const coordinates = [];
                    if (direction === 'Across') {
                        let i = x;
                        while (i < puzzle[y].length && puzzle[y][i] !== 'NULL' ) {
                            coordinates.push({ x: i, y });
                            i++;
                        }
                    } else if (direction === 'Down') {
                        let i = y;
                        while (i < puzzle.length && puzzle[i][x] !== 'NULL' ) {
                            coordinates.push({ x, y: i });
                            i++;
                        }
                    }
                    return coordinates;
                }
            }
        }
        return null;
    }, [puzzle]);
    
    useEffect(() => {
        const newClueCoordinates = {};
        for (const direction in clues) {
            clues[direction].forEach(clue => {
                const coordinates = findClueCoordinates(clue[0], direction);
                if (coordinates) {
                    newClueCoordinates[`${direction}${clue[0]}`] = coordinates;
                }
            });
        }
        setClueCoordinates(newClueCoordinates);
    }, [puzzle, clues, findClueCoordinates]);

    const fetchData = useCallback(async () => {
        const storageKey = getStorageKey();
        try {
            const { value: allStatesString } = await Preferences.get({ key: storageKey });
            const allStates = allStatesString ? JSON.parse(allStatesString) : {};
            const savedState = allStates[currentDate];
            if (savedState) {
                setCrosswordData(savedState.crosswordData);
                setClues(savedState.clues);
                setDimensions(savedState.dimensions);
                setPuzzle(savedState.puzzle);
                setSolution(savedState.solution);
                setUserAnswers(savedState.userAnswers);
                setTextColors(savedState.textColors);
                setIsCompleted(savedState.isCompleted);
                setActiveCell(savedState.activeCell);
                setSelectedDimension(savedState.selectedDimension);
                setClueCoordinates(savedState.clueCoordinates);
                setCircledCells(savedState.circledCells || []);
                setCompletionTime(savedState.completionTime || 0);
                setIsTimerRunning(!savedState.isCompleted);
                setLockedSquares(savedState.lockedSquares)
                setShareString(savedState.shareString)
                if (savedState.isCompleted) {
                    setTimeout(() => { setIsModalOpen(true); }, 750); 
                }
            } else {
                const data = await loadCrosswordData(type, currentDate);    
                setCrosswordData(data);
                setClues(data.clues);
                setDimensions(data.dimensions);
                setPuzzle(data.puzzle);
                setSolution(data.solution);
                setCircledCells(data.circledCells || []);
                setCompletionTime(0);
                setIsTimerRunning(true);
                // Initialize userAnswers and textColors
                const initialUserAnswers = Array.from({ length: data.puzzle.length }, () =>
                    Array.from({ length: data.puzzle[0].length }, () => '')
                );
                setUserAnswers(initialUserAnswers);
                
                const initialTextColors = Array.from({ length: data.puzzle.length }, () =>
                    Array.from({ length: data.puzzle[0].length }, () => 'text-black')
                );
                setTextColors(initialTextColors);
                updatePlayHistory(currentDate, type, 'started');
                sendGameData(false, false, type);
            }
            setIsDataLoaded(true);
        } catch (error) {
            setDataLoadFailed(true)
            console.error('Error fetching data:', error);
        }
    }, [type, currentDate, getStorageKey, sendGameData]);

    useEffect(() => {
        if (currentDate) {
            fetchData();
        }
    }, [fetchData, currentDate]);

    useEffect(() => {
        if (crosswordData && puzzle) {
            const findStartingCell = () => {
                for (let row = 0; row < dimensions.height; row++) {
                    for (let col = 0; col < dimensions.width; col++) {
                        if (puzzle[row][col] === "1") {
                            return { row, col };
                        }
                    }
                }
                return { row: 0, col: 0 };
            };
            setActiveCell(findStartingCell());
        }
    }, [crosswordData, puzzle, dimensions]);

    const textSizeClasses = useMemo(() => {
        if (!dimensions) return { cell: '', label: '' };
    
        const rows = dimensions.height;
        const cols = dimensions.width;
        const screenWidth = window.innerWidth;

        if (rows > 10 || cols > 10 || screenWidth < 350) {
            return {
                cell: 'text-sm',
                label: 'text-xxs'
            };
        } else if (rows > 7 || cols > 7) {
            return {
                cell: 'text-md',
                label: 'text-xs'
            };
        } else if (rows > 5 || cols > 5) {
            return {
                cell: 'text-xl',
                label: 'text-sm'
            };
        } else {
            return {
                cell: 'text-2xl',
                label: 'text-xl'
            };
        }

    }, [dimensions]);

    const handleCellClick = (rowIndex, colIndex) => {
        if (activeCell && activeCell.row === rowIndex && activeCell.col === colIndex) {
            setSelectedDimension(prevDimension => prevDimension === "row" ? "column" : "row");
        } else {
            setActiveCell({ row: rowIndex, col: colIndex });
        }
    };

    const isCellCircled = useCallback((rowIndex, colIndex) => {
        return circledCells.some(cell => cell[0] === rowIndex && cell[1] === colIndex);
    }, [circledCells]);

    const getCellClassName = (rowIndex, colIndex) => {
        let className = `relative w-full h-full border border-solid border-gray-300 text-center uppercase ${textSizeClasses.cell} `;        
        if (rowIndex === activeCell.row && colIndex === activeCell.col) {
            className += "bg-accent ";
        } else if (isWithinActiveClue(rowIndex, colIndex, activeCell, selectedDimension, puzzle)) {
            className += "bg-secondary ";
        } else if (currentClueReferences.some(cell => cell.x === colIndex && cell.y === rowIndex)) {
            className += "bg-gray-200 ";
        }
        if (isCellCircled(rowIndex, colIndex)) {
            className += "circled-cell ";
        }
        return className;
    };    

    const isWithinActiveClue = (rowindex, columnindex, activeCell, selectedDimension, puzzle) => {
        if (selectedDimension === "row") {
            if (rowindex !== activeCell.row) {
                return false;
            }
            const startCol = Math.min(activeCell.col, columnindex);
            const endCol = Math.max(activeCell.col, columnindex);
            for (let col = startCol; col <= endCol; col++) {
                if (puzzle[rowindex][col] === '#' ||  puzzle[rowindex][col] === 'NULL') {
                    return false;
                }
            }
        } else if (selectedDimension === "column") {
            if (columnindex !== activeCell.col) {
                return false;
            }
            const startRow = Math.min(activeCell.row, rowindex);
            const endRow = Math.max(activeCell.row, rowindex);
            for (let row = startRow; row <= endRow; row++) {
                if (puzzle[row][columnindex] === '#'||  puzzle[row][columnindex] === 'NULL') {
                    return false;
                }
            }
        } else {
            return false;
        }    
        return true;
    };

    useEffect(() => {
        const style = document.createElement('style');
        document.head.appendChild(style);
        style.sheet.insertRule(`input::selection { background: transparent; }`, 0);
    }, []);

    const checkIfSolved = (updatedAnswers) => {
        for (let row = 0; row < updatedAnswers.length; row++) {
            for (let col = 0; col < updatedAnswers[row].length; col++) {
                if (updatedAnswers[row][col] !== solution[row][col] && solution[row][col] !== "#" && solution[row][col] !== "NULL") {
                    return false;
                }
            }
        }
        updatePlayHistory(currentDate, type, 'won');
        checkAndroidAndLogEvent(type)
        return true;
    };

    const handleInputChange = (rowIndex, colIndex, event) => {
        const key = event.key || '';
        let value;
        if (lockedSquares && lockedSquares.some(square => square.row === rowIndex && square.col === colIndex)) {
            if (event.code === 'Backspace' || event.code === 'Delete' || event.key === 'Backspace') {
                const previousCell = findPreviousCell(rowIndex, colIndex);
                if (previousCell) {
                    setActiveCell(previousCell);
                }
            }
            return;
        }
        if (isCompleted) {
            return
        } else if (/^[a-zA-Z]$/.test(key)) {
            value = key.toUpperCase();
        } else if (event.code === 'Backspace' || event.code === 'Delete' || event.key === 'Backspace') {
            const { row, col } = activeCell;
            if (userAnswers[row][col] === '') {
                const previousCell = findPreviousCell(rowIndex, colIndex);
                if (previousCell) {
                    setActiveCell(previousCell);
                    if (!(lockedSquares && lockedSquares.some(square => 
                        square.row === previousCell.row && square.col === previousCell.col
                    ))) {
                        userAnswers[previousCell.row][previousCell.col] = '';
                    }
                }
            }
            value = '';
        } else if (event.code === 'Tab') {
            event.preventDefault();
            handleNextClue();
            return;
        } else if (key === 'SWITCH') {
            setSelectedDimension(prevDimension => prevDimension === "row" ? "column" : "row");
            return;
        } else {
            return;
        }
    
        const updatedAnswers = userAnswers.map((row, rIdx) =>
            row.map((cell, cIdx) => (rIdx === rowIndex && cIdx === colIndex ? value : cell))
        );
        setUserAnswers(updatedAnswers);
    
        const updatedColors = textColors.map((row, rIdx) =>
            row.map((cell, cIdx) => (rIdx === rowIndex && cIdx === colIndex ? (value ? 'text-black' : 'text-black') : cell))
        );
        setTextColors(updatedColors);
    
        if (value !== '') {
            setTimeout(() => {
                findNextEmptyCell(rowIndex, colIndex);
            }, 0);
        }
        if (checkIfSolved(updatedAnswers)) {
            setIsCompleted(true);
            setGameWon(true);
            setShareString(`I solved the PuzPop ${type} on ${currentDate} in ${formatTime(completionTime)}!`);
            setIsTimerRunning(false);
            updatePlayHistory(currentDate, type, 'won');
            sendGameData(true, false, type);
            setTimeout(() => { setIsModalOpen(true); }, 750); 
        }
    };

    const findPreviousCell = (rowIndex, colIndex) => {
        while (rowIndex > 0 || colIndex > 0) {
            if (selectedDimension === "row") {
                if (colIndex > 0) {
                    colIndex--;
                } else if (rowIndex > 0) {
                    rowIndex--;
                    colIndex = puzzle[rowIndex].length - 1;
                }
            } else if (selectedDimension === "column") {
                if (rowIndex > 0) {
                    rowIndex--;
                } else if (colIndex > 0) {
                    colIndex--;
                    rowIndex = puzzle.length - 1;
                }
            }
            if (!['#', null, 'NULL'].includes(puzzle[rowIndex][colIndex])) {
                return { row: rowIndex, col: colIndex };
            }
        }
        return { row: 0, col: 0 };
    };
    
    const findNextEmptyCell = (rowIndex, colIndex) => {
        let dontExecute = false;
        if (selectedDimension === "row") {
            let nextRow = rowIndex;
            let nextCol = colIndex + 1;
            outerloop:
            while (nextRow < puzzle.length) {
                while (nextCol < puzzle[nextRow].length) {
                    if (userAnswers[nextRow][nextCol] === '' && puzzle[nextRow][nextCol] !== '#' && puzzle[nextRow][nextCol] !== null && puzzle[nextRow][nextCol] !== 'NULL') {
                        setActiveCell({ row: nextRow, col: nextCol });
                        return;
                    } else if (['#', null, 'NULL'].includes(puzzle[nextRow][nextCol])) {
                        dontExecute = true
                        handleNextClue();
                        break outerloop;
                    }
                    nextCol++;
                }
                nextCol = 0;
                nextRow++;
            }
        } else if (selectedDimension === "column") {
            let nextCol = colIndex;
            let nextRow = rowIndex + 1;
            outerloop2:
            while (nextCol < puzzle[0].length) {
                while (nextRow < puzzle.length) {
                    if (['#', null, 'NULL'].includes(puzzle[nextRow][nextCol]) || nextRow === 0) {
                        dontExecute = true
                        handleNextClue();
                        break outerloop2;
                    }
                    else if (userAnswers[nextRow][nextCol] === '' && puzzle[nextRow][nextCol] !== '#' && puzzle[nextRow][nextCol] !== null && puzzle[nextRow][nextCol] !== 'NULL') {
                        setActiveCell({ row: nextRow, col: nextCol });
                        return;
                    } 
                    nextRow++;
                }
                nextRow = 0;
                nextCol++;
            }
        }
        if (dontExecute === false) {
            handleNextClue();
        }
    };
    
    const resetPuzzle = () => {
        setUserAnswers(Array.from({ length: puzzle.length }, () =>
            Array.from({ length: puzzle[0].length }, () => '')
        ));
        setTextColors(Array.from({ length: puzzle.length }, () =>
            Array.from({ length: puzzle[0].length }, () => 'text-black')
        ));
        setDropdownOpen(false);
        setCompletionTime(0);
        setIsTimerRunning(true);
        setLockedSquares([]);
        setIsCompleted(false);
    };

    const checkSquare = () => {
        const { row, col } = activeCell;
        if (userAnswers[row][col] === solution[row][col]) {
            const updatedColors = textColors.map((r, rIdx) =>
                r.map((c, cIdx) => (rIdx === row && cIdx === col ? 'text-blue-500' : c))
            );
            setTextColors(updatedColors);
            setLockedSquares(prev => Array.isArray(prev) ? [...prev, { row, col }] : [{ row, col }]);
        } else {
            const updatedColors = textColors.map((r, rIdx) =>
                r.map((c, cIdx) => (rIdx === row && cIdx === col ? 'text-red-500' : c))
            );
            setTextColors(updatedColors);
        }
    };

    const checkWord = () => {
        const { row, col } = activeCell;
        const isRow = selectedDimension === 'row';
        let start = isRow ? col : row;
        let end = start;
        const fixed = isRow ? row : col;
        const length = isRow ? puzzle[row].length : puzzle.length;
    
        while (start > 0 && puzzle[isRow ? fixed : start - 1][isRow ? start - 1 : fixed] !== '#' && 
               puzzle[isRow ? fixed : start - 1][isRow ? start - 1 : fixed] !== 'NULL') {
            start--;
        }
        while (end < length - 1 && puzzle[isRow ? fixed : end + 1][isRow ? end + 1 : fixed] !== '#' && 
               puzzle[isRow ? fixed : end + 1][isRow ? end + 1 : fixed] !== 'NULL') {
            end++;
        }
    
        const newLockedSquares = [];
        const updatedColors = textColors.map((r, rIdx) =>
            r.map((c, cIdx) => {
                const isInWord = isRow ? 
                    (rIdx === row && cIdx >= start && cIdx <= end) :
                    (cIdx === col && rIdx >= start && rIdx <= end);
                
                if (!isInWord) return c;
    
                const current = isRow ? 
                    { row: rIdx, col: cIdx } : 
                    { row: rIdx, col: cIdx };
                const isCorrect = userAnswers[current.row][current.col] === solution[current.row][current.col];
                
                if (isCorrect) {
                    newLockedSquares.push(current);
                }
                
                return isCorrect ? 'text-blue-500' : 'text-red-500';
            })
        );
    
        setTextColors(updatedColors);
        setLockedSquares(prev => Array.isArray(prev) ? 
            [...new Map([...prev, ...newLockedSquares].map(square => 
                [`${square.row}-${square.col}`, square]
            )).values()] 
            : newLockedSquares
        );
    };
    
    const checkPuzzle = () => {
        const newLockedSquares = [];
        const newTextColors = userAnswers.map((row, rowIndex) =>
            row.map((cell, colIndex) => {
                if (lockedSquares?.some(square => square.row === rowIndex && square.col === colIndex)) {
                    return textColors[rowIndex][colIndex];
                }
                if (cell === '') {
                    return 'text-black';
                }
                const isCorrect = cell === solution[rowIndex][colIndex];
                if (isCorrect) {
                    newLockedSquares.push({ row: rowIndex, col: colIndex });
                }
                return isCorrect ? 'text-blue-500' : 'text-red-500';
            })
        );
    
        setTextColors(newTextColors);
        setLockedSquares(prev => Array.isArray(prev) ? 
            [...new Map([...prev, ...newLockedSquares].map(square => 
                [`${square.row}-${square.col}`, square]
            )).values()] 
            : newLockedSquares
        );
        setDropdownOpen(false);
    };

    const revealSquare = () => {
        const { row, col } = activeCell;
        const updatedAnswers = userAnswers.map((r, rIdx) =>
            r.map((c, cIdx) => (rIdx === row && cIdx === col ? solution[row][col] : c))
        );
        setUserAnswers(updatedAnswers);
    
        const updatedColors = textColors.map((r, rIdx) =>
            r.map((c, cIdx) => (rIdx === row && cIdx === col ? 'text-success' : c))
        );
        setTextColors(updatedColors);
        setLockedSquares(prev => Array.isArray(prev) ? [...prev, { row, col }] : [{ row, col }]);
        if (checkIfSolved(updatedAnswers)) {
            setIsCompleted(true);
            setIsTimerRunning(false);
            sendGameData(false, true, type);
            setShareString(`I solved the PuzPop ${type} on ${currentDate} in ${formatTime(completionTime)}!`);
            setTimeout(() => { setIsModalOpen(true); }, 750); 
        }
    };    

    const revealWord = () => {
        const { row, col } = activeCell;
        const isRow = selectedDimension === 'row';
        let start = isRow ? col : row;
        let end = start;
        const fixed = isRow ? row : col;
        const length = isRow ? puzzle[row].length : puzzle.length;
    
        while (start > 0 && puzzle[isRow ? fixed : start - 1][isRow ? start - 1 : fixed] !== '#' && 
               puzzle[isRow ? fixed : start - 1][isRow ? start - 1 : fixed] !== 'NULL') {
            start--;
        }
        while (end < length - 1 && puzzle[isRow ? fixed : end + 1][isRow ? end + 1 : fixed] !== '#' && 
               puzzle[isRow ? fixed : end + 1][isRow ? end + 1 : fixed] !== 'NULL') {
            end++;
        }
    
        const newLockedSquares = [];
        const updatedAnswers = userAnswers.map((r, rIdx) =>
            r.map((c, cIdx) => {
                const isInWord = isRow ? 
                    (rIdx === row && cIdx >= start && cIdx <= end) :
                    (cIdx === col && rIdx >= start && rIdx <= end);
                
                if (!isInWord) return c;
    
                if (lockedSquares && lockedSquares.some(square => 
                    square.row === rIdx && square.col === cIdx
                )) {
                    return c;
                }
    
                newLockedSquares.push({ row: rIdx, col: cIdx });
                return isRow ? solution[row][cIdx] : solution[rIdx][col];
            })
        );
        setUserAnswers(updatedAnswers);
    
        const updatedColors = textColors.map((r, rIdx) =>
            r.map((c, cIdx) => {
                const isInWord = isRow ? 
                    (rIdx === row && cIdx >= start && cIdx <= end) :
                    (cIdx === col && rIdx >= start && rIdx <= end);
                
                if (!isInWord) return c;
    
                if (lockedSquares && lockedSquares.some(square => 
                    square.row === rIdx && square.col === cIdx
                )) {
                    return c;
                }
    
                return 'text-success';
            })
        );
        setTextColors(updatedColors);
    
        setLockedSquares(prev => Array.isArray(prev) ? 
            [...new Map([...prev, ...newLockedSquares].map(square => 
                [`${square.row}-${square.col}`, square]
            )).values()] 
            : newLockedSquares
        );
    
        if (checkIfSolved(updatedAnswers)) {
            setIsCompleted(true);
            setIsTimerRunning(false);
            sendGameData(false, true, type);
            setShareString(`I solved the PuzPop ${type} on ${currentDate} in ${formatTime(completionTime)}!`);
            setTimeout(() => { setIsModalOpen(true); }, 750); 
        }
    };
        
    const revealPuzzle = () => {
        const newLockedSquares = [];
        
        const updatedAnswers = userAnswers.map((row, rIdx) =>
            row.map((cell, cIdx) => {
                if (solution[rIdx][cIdx] === 'NULL' || 
                    (lockedSquares && lockedSquares.some(square => 
                        square.row === rIdx && square.col === cIdx
                    ))) {
                    return cell;
                }
                newLockedSquares.push({ row: rIdx, col: cIdx });
                return solution[rIdx][cIdx];
            })
        );
        
        const updatedColors = textColors.map((row, rIdx) =>
            row.map((cell, cIdx) => {
                if (solution[rIdx][cIdx] === 'NULL' || 
                    (lockedSquares && lockedSquares.some(square => 
                        square.row === rIdx && square.col === cIdx
                    ))) {
                    return cell;
                }
                return 'text-success';
            })
        );
    
        setUserAnswers(updatedAnswers);
        setTextColors(updatedColors);
        setLockedSquares(prev => Array.isArray(prev) ? 
            [...new Map([...prev, ...newLockedSquares].map(square => 
                [`${square.row}-${square.col}`, square]
            )).values()] 
            : newLockedSquares
        );
    
        if (checkIfSolved(updatedAnswers)) {
            setIsCompleted(true);
            setIsTimerRunning(false);
            updatePlayHistory(currentDate, type, 'lost');
            setShareString(`I solved the PuzPop ${type} on ${currentDate} in ${formatTime(completionTime)}!`);
            setTimeout(() => { setIsModalOpen(true); }, 750); 
        }
        sendGameData(false, true, type);
    };

    const getClue = useCallback((direction, activeCell) => {
        const { row, col } = activeCell; 
        let clueNumber;   
        if (direction === 'row') {
            for (let colIndex = col; colIndex >= 0; colIndex--) {
                let current = typeof puzzle[row][colIndex] === 'object' ? puzzle[row][colIndex].cell : puzzle[row][colIndex];
                if (current !== 'NULL') {
                    clueNumber = current;
                }
                if (colIndex === 0) {
                    return { number: clueNumber, direction: 'Across' };
                } else if (puzzle[row][colIndex - 1] === '#' || puzzle[row][colIndex - 1] === 'NULL') {
                    return { number: clueNumber, direction: 'Across' };
                }
            }
        } else if (direction === 'column') {
            for (let rowIndex = row; rowIndex >= 0; rowIndex--) {
                let current = typeof puzzle[rowIndex][col] === 'object' ? puzzle[rowIndex][col].cell : puzzle[rowIndex][col];
                if (current !== 'NULL') {
                    clueNumber = current;
                }
                if (rowIndex === 0) {
                    return { number: clueNumber, direction: 'Down' };
                } else if (puzzle[rowIndex - 1][col] === '#' || puzzle[rowIndex - 1][col] === 'NULL') {
                    return { number: clueNumber, direction: 'Down' };
                }
            }
        }
        console.log("No clue found after full check.");
        return null;
    }, [puzzle]);

    const getCurrentClue = () => {
        if (!clues) return;
        const dimension = selectedDimension.toLowerCase();
        const clueData = getClue(dimension, activeCell);
        if (!clueData) {
            console.log("No clue found.");
            return "";
        }
        const { number, direction } = clueData;
        const theClue = clues[direction].find(clue => clue[0] === number);
        const clueText = theClue ? theClue[1] : "";
        return clueText;
    };

    const clueText = getCurrentClue();
    const clueTextSize = useMemo(() => {
        if (clueText && clueText.length > 65) {
            return 'text-sm';
        } else if (clueText && clueText.length > 35) {
            return 'text-md';
        } else {
            return 'text-xl';
        }
    }, [clueText]);

    const parseCurrentClueForReferences = useCallback(() => {
        if (!clues || !puzzle || !activeCell) return;

        const currentClue = getClue(selectedDimension, activeCell);
        if (!currentClue) return;

        const { number, direction } = currentClue;
        const clueText = clues[direction].find(clue => clue[0] === number)?.[1];
        if (!clueText) return;

        const clueRegex = /\b(\d+)[-\s]?(across|down|a|d)\b/gi;
        const matches = [...clueText.matchAll(clueRegex)];
        
        const newReferencedCells = [];
        matches.forEach(match => {
            const [, refNumber, refDirection] = match;
            const fullDirection = refDirection.toLowerCase().startsWith('a') ? 'Across' : 'Down';
            const refClueKey = `${fullDirection}${refNumber}`;
            if (clueCoordinates[refClueKey]) {
                newReferencedCells.push(...clueCoordinates[refClueKey]);
            }
        });

        setCurrentClueReferences(newReferencedCells);
    }, [clues, puzzle, activeCell, selectedDimension, clueCoordinates, getClue]);

    useEffect(() => {
        parseCurrentClueForReferences();
    }, [parseCurrentClueForReferences]);
    
    const handleNextClue = () => {
        let currentClue = getClue(selectedDimension, activeCell); // this gives output like {number: '23', direction: 'Across'}
        let clueKey = `${currentClue.direction}${currentClue.number}`;
        const clueKeys = Object.keys(clueCoordinates);
        const currentIndex = clueKeys.indexOf(clueKey);
        if (currentIndex === -1) {
            console.log("Current clue not found in clueCoordinates");
            return;
        }
        let i = currentIndex + 1;
        if (i >= clueKeys.length) i = 0;
        while (true) {
            const nextClueKey = clueKeys[i];
            const coordinates = clueCoordinates[nextClueKey];
            for (let j = 0; j < coordinates.length; j++) {
                const { x, y } = coordinates[j];
                if (userAnswers[y][x] === '') {
                    setActiveCell({ row: y, col: x });
                    if (nextClueKey.includes('Across')) {
                        setSelectedDimension('row');
                    } else {
                        setSelectedDimension('column');
                    }
                    return;
                }
            }
            i++;
            if (i >= clueKeys.length) i = 0;
            if (i === currentIndex) break;
        }
        console.log("No empty coordinate found in next clues");
    };
    
    const handlePreviousClue = () => {
        let currentClue = getClue(selectedDimension, activeCell);
        let clueKey = `${currentClue.direction}${currentClue.number}`;
        const clueKeys = Object.keys(clueCoordinates);
        const currentIndex = clueKeys.indexOf(clueKey);
        if (currentIndex === -1) {
            console.log("Current clue not found in clueCoordinates");
            return;
        }
        let i = currentIndex - 1;
        if (i < 0) i = clueKeys.length - 1;
        while (true) {
            const previousClueKey = clueKeys[i];
            const coordinates = clueCoordinates[previousClueKey];
            for (let j = 0; j < coordinates.length; j++) {
                const { x, y } = coordinates[j];
                if (userAnswers[y][x] === '') {
                    setActiveCell({ row: y, col: x });
                    if (previousClueKey.includes('Across')) {
                        setSelectedDimension('row');
                    } else {
                        setSelectedDimension('column');
                    }
                    return;
                }
            }
            i--;
            if (i < 0) i = clueKeys.length - 1;
            if (i === currentIndex) break;
        }
        console.log("No empty coordinate found in previous clues");
    };
    
    useEffect(() => {
        const isValidCell = (row, col) => {
            return row >= 0 && row < puzzle.length && col >= 0 && col < puzzle[0].length && puzzle[row][col] !== null && puzzle[row][col] !== 'NULL' && puzzle[row][col] !== '#';
        };

        const handleKeyDown = (event) => {
            const { key } = event;
            const { row, col } = activeCell;
            
            let newRow = row;
            let newCol = col;
            let handled = false;
        
            switch (key) {
                case 'ArrowLeft':
                    if (selectedDimension === 'column') {
                        setSelectedDimension("row");
                    } else {
                        newCol -= 1;
                        if (isValidCell(newRow, newCol)) {
                            setActiveCell({ row: newRow, col: newCol });
                            handled = true;
                        }
                    }
                    break;
                case 'ArrowRight':
                    if (selectedDimension === 'column') {
                        setSelectedDimension("row");
                    } else {
                        newCol += 1;
                        if (isValidCell(newRow, newCol)) {
                            setActiveCell({ row: newRow, col: newCol });
                            handled = true;
                        }
                    }
                    break;
                case 'ArrowUp':
                    if (selectedDimension === 'row') {
                        setSelectedDimension("column");
                    } else {
                        newRow -= 1;
                        if (isValidCell(newRow, newCol)) {
                            setActiveCell({ row: newRow, col: newCol });
                            handled = true;
                        }
                    }
                    break;
                case 'ArrowDown':
                    if (selectedDimension === 'row') {
                        setSelectedDimension("column");
                    } else {
                        newRow += 1;
                        if (isValidCell(newRow, newCol)) {
                            setActiveCell({ row: newRow, col: newCol });
                            handled = true;
                        }
                    }
                    break;
                default:
                    break;
            }
        
            if (handled) {
                event.preventDefault();
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [activeCell, selectedDimension, puzzle]);

    const cellRefs = useRef(new Map());

    const handleFocus = (row, col) => {
        const cellKey = `${row}-${col}`;
        cellRefs.current.get(cellKey)?.focus();
    };
    
    useEffect(() => {
        handleFocus(activeCell.row, activeCell.col);
    }, [activeCell]);
    
    const handleKeyboardInput = (key) => {
        console.log(key)
        const { row, col } = activeCell;
        if (key === 'BACK') {
            handleInputChange(row, col, { key: 'Backspace' });
        } else {
            handleInputChange(row, col, { key });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.dropdown')) {
                setDropdownOpen(false);
            }
        };
    
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    
    return (
        <div>
            <div className="p-2 shadow-b flex justify-between items-center pb-0 pt-0 rajdhani-font">
                <div className="flex items-center">
                    <button onClick={handleBackClick} className="btn btn-ghost">
                        <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                    </button>
                    <div className="ml-2 w-12">
                        <Timer 
                            time={completionTime}
                            isRunning={isTimerRunning}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-center flex-grow">
                    {type === "crossword" ? (
                        <>
                            <img src={bigCrossword} alt="Crossword" className="h-6 mr-2 dark:invert" />
                            <span className="font-bold text-lg">Crossword</span>
                        </>
                    ) : (
                        <>
                            <img src={miniCrossword} alt="Mini Crossword" className="h-6 mr-2 dark:invert" />
                            <span className="font-bold text-lg">Mini</span>
                        </>
                    )}
                </div>
                <div className="dropdown dropdown-end">
                    <button 
                        className="btn btn-ghost btn-circle mr-0"
                        onClick={() => setIsInfoModalOpen(true)}
                    >
                        <FontAwesomeIcon icon={faCircleInfo} className="text-2xl" />
                    </button>
                    <button tabIndex="0" className="btn btn-ghost btn-circle" onClick={() => setDropdownOpen(!isDropdownOpen)}>
                        <FontAwesomeIcon icon={faEllipsisVertical} className="text-2xl" />
                    </button>
                    {isDropdownOpen && (
                        <ul tabIndex="0" className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50 text-xl ">
                            <li><button onClick={() => handleConfirmAction('Check Square')}>Check Square</button></li>
                            <li><button onClick={() => handleConfirmAction('Check Word')}>Check Word</button></li>
                            <li><button onClick={() => handleConfirmAction('Check Puzzle')}>Check Puzzle</button></li>
                            <li><button onClick={() => handleConfirmAction('Reveal Square')}>Reveal Square</button></li>
                            <li><button onClick={() => handleConfirmAction('Reveal Word')}>Reveal Word</button></li>
                            <li><button onClick={() => handleConfirmAction('Reveal Puzzle')}>Reveal Puzzle</button></li>
                            <li><button onClick={() => handleConfirmAction('Reset Puzzle')}>Reset Puzzle</button></li>
                        </ul>
                    )}
                </div>
            </div>
            {isDataLoaded ? (
            <div className="flex-grow flex flex-col items-center justify-center overflow-hidden">
                <div style={{ 
                    display: 'grid',
                    gridTemplateColumns: `repeat(${dimensions.width}, 1fr)`,
                    gridTemplateRows: `repeat(${dimensions.height}, 1fr)`,
                    width: '100%',
                    maxWidth: '600px',
                    height: `${gridSize.height}px`,
                    maxHeight: '100%',
                    aspectRatio: `${dimensions.width} / ${dimensions.height}`,
                }}>
                {puzzle.map((row, rowIndex) =>
                    row.map((cell, colIndex) => {
                        const key = `${rowIndex}-${colIndex}`;
                        const cellValue = typeof cell === 'object' && cell !== null ? cell.cell : cell;
                        const label = (typeof cellValue === 'number' && cellValue !== 0) || 
                            (typeof cellValue === 'string' && !isNaN(+cellValue) && +cellValue !== 0) ? 
                                <span className={`absolute top-0 left-0 ${textSizeClasses.label} p-0.5 dark:text-black`} 
                                    style={{ userSelect: 'none', zIndex: 10 }}>
                                    {typeof cellValue === 'string' ? +cellValue : cellValue}
                                </span> : null;
                        if (cell === null || cell === '#' || cell === 'NULL') {
                            return <div key={key} className="w-full h-full bg-black"></div>;
                        }
                        return (
                            <div key={key} onClick={() => handleCellClick(rowIndex, colIndex)} className="relative">
                                {label}
                                <input
                                    ref={el => cellRefs.current.set(key, el)}
                                    type="text"
                                    maxLength="1"
                                    value={userAnswers[rowIndex][colIndex]}
                                    onChange={(e) => handleInputChange(rowIndex, colIndex, e)}
                                    onKeyDown={(e) => handleInputChange(rowIndex, colIndex, e)}
                                    onFocus={() => handleFocus(rowIndex, colIndex)}
                                    readOnly={true}
                                    inputMode="none"
                                    className={`${getCellClassName(rowIndex, colIndex)} ${textColors[rowIndex][colIndex]}`}
                                    style={{ caretColor: 'transparent' }}
                                />
                                {isCellCircled(rowIndex, colIndex) && (
                                    <div className="absolute inset-0 rounded-full border-2 border-gray-400 pointer-events-none"></div>
                                )}
                            </div>
                        );
                    })
                )}
                </div>
            </div>
            ) : !dataLoadFailed ? (
                <div className="flex flex-col items-center justify-center">
                <h2 className="text-2xl font-bold mb-4">Loading</h2>
                <span className="loading loading-dots loading-lg"></span>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center text-center">
                    <h2 className="text-xl font-bold mb-4 mt-8 px-16">There was an error loading the game.</h2>
                    <FontAwesomeIcon icon={faFaceDisappointed} className="text-4xl mt-4" />
                    <h2 className="text-xl font-bold mb-4 mt-8 px-16">Please try again later.</h2>
                </div>
            )}
            {clues ? (
            <div className={`bg-secondary w-full max-w-[600px] mx-auto fixed bottom-[220px] flex items-center justify-between dark:text-black ${keyboardMargin}`} style={{ height: '60px' }}>
                <button onClick={handlePreviousClue} className="p-4">
                    <FontAwesomeIcon icon={faChevronLeft} className="text-2xl pt-1" />
                </button>
                <p 
                    className={`flex-grow text-center ${clueTextSize} cursor-pointer select-none`} 
                    onClick={() => setSelectedDimension(prevDimension => prevDimension === "row" ? "column" : "row")}
                >
                    {clueText}
                </p>
                <button onClick={handleNextClue} className="p-4">
                    <FontAwesomeIcon icon={faChevronRight} className="text-2xl pt-1" />
                </button>
            </div>
            ) : (
                <div></div>
            )}
            <CrossKeyboard onKeyPress={handleKeyboardInput} />
            <CompletedModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} game={type} shareText={shareString} currentDate={currentDate} onDateChange={handleDateChange} completionTime={completionTime} gameWon={gameWon} />
            {gameWon && <RatingAlert/>}
            {isConfirmModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 dark:text-black">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Confirmation</h2>
                        <p className="mb-4">Are you sure you want to {confirmAction}?</p>
                        <div className="flex justify-end">
                            <button 
                                className="btn btn-ghost mr-5"
                                onClick={() => setIsConfirmModalOpen(false)}
                            >
                                Cancel
                            </button>
                            <button 
                                className="btn btn-primary mr-2 text-white"
                                onClick={executeConfirmedAction}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <CrosswordInfo 
                isOpen={isInfoModalOpen}
                onClose={() => setIsInfoModalOpen(false)}
                crosswordData={crosswordData}
            />
        </div>
    );
}

export default Mini;
