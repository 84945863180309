// Home.js
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDashed, faCircleCheck } from '@awesome.me/kit-636b1434d3/icons/classic/solid';
import Header from './Header';
import Footer from './Footer';
import bigCrossword from '../assets/Big-Crossword.png';
import miniCrossword from '../assets/Mini-Crossword.png';
import lightbulbOnLight from '../assets/lightbulb-on-light.png';
// import newsQuiz from '../assets/news-quiz.png';
import sudoku from '../assets/Sudoku.png';
import clique from '../assets/square-ellipsis-light.png';
import proximity from '../assets/bullseye-arrow-light.png';
import { getGameStatus } from './utils/playHistoryUtil';
import { gameStartDates } from './utils/gameConstants';
import { Device } from '@capacitor/device';
import { getStoredUserId } from './utils/Userid';
import { Preferences } from '@capacitor/preferences';
import { fetchCliqueData, loadCrosswordData } from './utils/FetchGameData';
import { getNewYorkDate, isFirstDateLess } from './utils/DateTime';

const customStyles = `

  .ios-full-height {
    height: 100%;
    position: fixed;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

function Home({ toggleTheme, theme, user, signOut, openAuthModal, onAuthStateChange }) {
  const [gameStatuses, setGameStatuses] = useState({});
  const [isIOS, setIsIOS] = useState(false);
  const [isIPad, setIsIPad] = useState(false);
  const [isWeb, setIsWeb] = useState(false);
  const [iPadMargin, setIPadMargin] = useState('0px');
  const nyDate = getNewYorkDate();
  const location = useLocation();
  const [currentDate, setCurrentDate] = useState(() => {
    return location.state?.date || getNewYorkDate();
  });

  const fetchTodaysGameData = useCallback(async () => {
    try {
      await fetchCliqueData(nyDate);
      await loadCrosswordData('crossword', nyDate);
      await loadCrosswordData('mini', nyDate);
    } catch (error) {
      console.error("Error fetching today's game data:", error);
    }
  }, [nyDate]);

  useEffect(() => {
    const checkUserId = async () => {
      const { value: userid } = await Preferences.get({ key: 'userid' });
      if (!userid) {
        await getStoredUserId();
      }
    };

    const fetchGameStatuses = async () => {
      const games = ['crossword', 'mini', 'ridella', 'clique', 'proximity', 'sudoku'];
      const statuses = {};

      for (const game of games) {
        statuses[game] = await getGameStatus(currentDate, game);
      }

      setGameStatuses(statuses);
    };

    const checkPlatform = async () => {
      if (window.Capacitor) {
        const info = await Device.getInfo();
        const { Capacitor } = window;
        setIsIOS(Capacitor.getPlatform() === 'ios');
        setIsWeb(Capacitor.getPlatform() === 'web');
        const isIPad = info.model.toLowerCase().includes('ipad');
        setIsIPad(isIPad);
        if (isIPad) {
          const margin = Math.max(0, (window.innerWidth - 600) / 2);
          setIPadMargin(`${margin}px`);
        }
      }
    };


    checkUserId();
    fetchGameStatuses();
    checkPlatform();
    fetchTodaysGameData();  
  }, [currentDate, fetchTodaysGameData]);

  const getStatusClass = (game) => {
    if (['completed', 'won', 'lost'].includes(gameStatuses[game])) {
      return 'ring-4 ring-success';
    } else if (['inprogress', 'started'].includes(gameStatuses[game])) {
      return 'ring-4 ring-info';
    }
    return '';
  };

  const getStatusIcon = (game) => {
    if (['completed', 'won', 'lost'].includes(gameStatuses[game])) {
      return <FontAwesomeIcon icon={faCircleCheck} className="text-success text-2xl absolute top-2 right-2" />;
    } else if (['inprogress', 'started'].includes(gameStatuses[game])) {
      return <FontAwesomeIcon icon={faCircleDashed} className="text-info text-2xl absolute top-2 right-2" />;
    }
    return null;
  };

  const handleGameClick = async (game) => {
    const { value: selectedFirstGame } = await Preferences.get({ key: 'selectedFirstGame' });
    if (!selectedFirstGame) {
      const { value: username } = await Preferences.get({ key: 'username' });
      if (username) {
        await axios.post("https://v7z3x44mg5.execute-api.us-east-1.amazonaws.com/prod", {
          username,
          first_selected_game: game
        });
        await Preferences.set({
          key: 'selectedFirstGame',
          value: 'true'
        });
      }
    }
  };  

  const GameCard = ({ to, game, style, imgSrc, altText, title, description }) => {
    if (isFirstDateLess(currentDate, gameStartDates[game])) {
      return null;
    }

    return (
      <Link 
        onClick={() => handleGameClick(game)}
        to={to}
        className={`card w-full aspect-square shadow-xl cursor-pointer relative ${getStatusClass(game)} flex flex-col justify-center items-center`} 
        style={style}
      >
        {getStatusIcon(game)}
        <div className="flex flex-col items-center justify-center w-full h-full xs:p-2 p-4">
          <figure className="w-1/3 xs:mb-1 mb-1">
            <img src={imgSrc} alt={altText} className="w-full h-full object-contain"/>
          </figure>
          <div className="text-center dark:text-black">
            <h2 className="font-bold xs:text-sm text-xl mb-2">{title}</h2>
            <p className="font-medium xs:text-xs text-sm">{description}</p>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className={`bg-base-100 flex flex-col ${isIOS ? 'ios-full-height' : 'min-h-screen'}` } style={isIPad ? { marginRight: iPadMargin } : {}}>
      <style>{customStyles}</style>
      <Header currentDate={currentDate} setCurrentDate={setCurrentDate} toggleTheme={toggleTheme} theme={theme} signOut={signOut} user={user} openAuthModal={openAuthModal} onAuthStateChange={onAuthStateChange}      />
      <div className="grid grid-cols-2 gap-6 p-8 xs:p-4 rajdhani-font">
        {/* 
        <Link to="/theweek" className="col-span-2 w-full">
          <div className={`card card-side aspect-w-2 aspect-h-1 shadow-xl cursor-pointer relative ${getStatusClass('theweek')}`} style={{ backgroundColor: "#C2C1EB" }}>
            {getStatusIcon('theweek')}
            <div className="card-body text-center">
              <h2 className="card-title text-2xl md:text-4xl mt-6">The Week</h2>
              <p className="text-xl md:text-2xl">Local News Quiz</p>
              <p className="text-base md:text-xl">Week of June 5th</p>
            </div>
            <figure className="flex-1 pt-5">
              <img src={newsQuiz} alt="The Week Quiz" className="w-3/4 h-3/4 object-contain"/>
            </figure>
          </div>
        </Link>
        */}

        <GameCard 
          to={`/crossword?type=crossword&date=${currentDate}`}
          game="crossword"
          style={{ backgroundColor: "#ecd4a6" }}
          imgSrc={bigCrossword}
          altText="Crossword"
          title="Crossword"
          description="Classic Grid"
        />
        <GameCard 
          to={`/crossword?type=mini&date=${currentDate}`}
          game="mini"
          style={{ backgroundColor: "#f68ea6" }}
          imgSrc={miniCrossword}
          altText="Mini Crossword"
          title="Mini"
          description="Bite-Sized Grid"
        />
        <GameCard 
          to={`/ridella?date=${currentDate}`}
          game="ridella"
          style={{ backgroundColor: "#C2C1EB" }}
          imgSrc={lightbulbOnLight}
          altText="Ridella"
          title="Ridella"
          description="Daily Riddle"
        />
        <GameCard 
          to={`/clique?date=${currentDate}`}
          game="clique"
          style={{ backgroundColor: "#A2D0D0" }}
          imgSrc={clique}
          altText="Clique"
          title="Clique"
          description="Related Terms"
        />
        <GameCard 
          to={`/proximity?date=${currentDate}`}
          game="proximity"
          style={{ backgroundColor: "#a4c6eb" }}
          imgSrc={proximity}
          altText="Proximity"
          title="Proximity"
          description="Semantic Relation"
        />
        <GameCard 
          to={`/sudoku?date=${currentDate}`}
          game="sudoku"
          style={{ backgroundColor: "#a1d0a6" }}
          imgSrc={sudoku}
          altText="Sudoku"
          title="Sudoku"
          description="Number Placement"
        />
      </div>
      {isWeb && <Footer />}
    </div>
  );
}

export default Home;