// Userid.js
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { Device } from '@capacitor/device';
import axios from 'axios';

const generateUserId = async () => {
    const randomPart = Array(9).fill()
        .map(() => Math.random().toString(36).charAt(2))
        .join('');
    const newId = `userid-${randomPart}`;
    return newId;
};

const isSimulatorOrEmulator = async () => {
    try {
        const deviceInfo = await Device.getInfo();
        return deviceInfo.isVirtual;
    } catch (error) {
        console.warn("Error detecting simulator/emulator:", error);
        return true; // Default to true if detection fails
    }
};

const uploadUser = async (userId) => {
    try {
        await axios.post('https://v7z3x44mg5.execute-api.us-east-1.amazonaws.com/prod', {
            username: userId,
            source: Capacitor.getPlatform(),
        });
        console.log('User ID uploaded successfully');
    } catch (error) {
        console.error('Error uploading User ID:', error);
    }
};

const getStoredUserId = async () => {
    try {
        // Try to get username first
        const { value: username } = await Preferences.get({ key: 'username' });
        if (username) {
            return username;
        }

        // Try to get userid
        const { value: id } = await Preferences.get({ key: 'userid' });
        if (id && id.startsWith('userid-')) return id;

        // If no valid ID found, generate a new one
        const newId = await generateUserId();
        
        // Store the new ID
        await Preferences.set({
            key: 'userid',
            value: newId
        });

        const isSim = await isSimulatorOrEmulator();
        if (!isSim) {
            await uploadUser(newId);
        } else {
            console.log('Skipped API call for simulator/emulator.');
        }

        return newId;
    } catch (e) {
        // If any unexpected error occurs, return a fresh ID
        return generateUserId();
    }
};

export { getStoredUserId };