// components/AppleSignInButton.js
import React from 'react';
import { SocialLogin } from '@capgo/capacitor-social-login';
import { Dialog } from '@capacitor/dialog';
import { setToken } from './AuthUtil';
import { Preferences } from '@capacitor/preferences';

const AppleSignInButton = ({ onAuthStateChange, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const showError = async (message) => {
    await Dialog.alert({
      title: 'Sign In Error',
      message: message
    });
  };

  const handleAppleSignIn = async () => {
    setIsLoading(true);
    try {
      const appleResult = await SocialLogin.login({
        provider: 'apple',
        options: {
          scopes: ['email', 'name'],
        },
      });
      console.log(appleResult)
      const { result } = appleResult;
      
      // Store tokens using your utility
      await setToken({
        accessToken: result.accessToken.token,
        idToken: result.idToken,
        // For Apple sign-in, we'll use the access token as refresh token
        refreshToken: result.accessToken.token
      });

      // Store user profile information
      const userProfile = {
        email: result.profile.email,
        name: `${result.profile.givenName} ${result.profile.familyName}`.trim(),
        givenName: result.profile.givenName,
        familyName: result.profile.familyName,
        userId: result.profile.user, // Apple's unique user ID
        provider: 'apple'
      };

      // Store user info in preferences
      await Promise.all([
        Preferences.set({ 
          key: 'username', 
          value: result.profile.email 
        }),
        Preferences.set({ 
          key: 'userProfile', 
          value: JSON.stringify(userProfile)
        })
      ]);

      if (onAuthStateChange) {
        onAuthStateChange();
      }
      if (onClose) {
        onClose();
      }

    } catch (error) {
      console.error('Error signing in with Apple:', error);
      await showError(error.message || 'Failed to sign in with Apple');
    } finally {
      setIsLoading(false);
    }
  };

  const renderButtonContent = () => {
    if (isLoading) {
      return (
        <>
          <svg 
            className="animate-spin h-5 w-5 mr-3" 
            viewBox="0 0 24 24"
          >
            <circle 
              className="opacity-25" 
              cx="12" 
              cy="12" 
              r="10" 
              stroke="currentColor" 
              strokeWidth="4"
              fill="none"
            />
            <path 
              className="opacity-75" 
              fill="currentColor" 
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          <span className="font-medium">Signing in...</span>
        </>
      );
    }

    return (
      <>
        <svg 
          className="w-5 h-5 mr-3" 
          viewBox="0 0 814 1000"
          aria-hidden="true"
        >
          <path 
            fill="currentColor" 
            d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"
          />
        </svg>
        <span className="font-medium">Sign in with Apple</span>
      </>
    );
  };

  return (
    <button 
      onClick={handleAppleSignIn}
      disabled={isLoading}
      className={`
        w-full px-4 py-3 
        flex items-center justify-center 
        rounded-lg
        border border-gray-300
        bg-black text-white
        transition-colors duration-200
        disabled:opacity-50 disabled:cursor-not-allowed
        ${!isLoading && 'hover:bg-gray-900'}
      `}
      aria-busy={isLoading}
    >
      {renderButtonContent()}
    </button>
  );
};

export default AppleSignInButton;