// components/GoogleSignInButton.js
import React, { useEffect } from 'react';
import { SocialLogin } from '@capgo/capacitor-social-login';
import { Preferences } from '@capacitor/preferences';
import { setToken } from './AuthUtil';

const GoogleSignInButton = ({ onAuthStateChange, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const initializeSocialLogin = async () => {
      try {
        await SocialLogin.initialize({
          google: {
            iOSClientId: '870445845698-gjktj2n2mdk134kcflqd4kidn0n30qon.apps.googleusercontent.com',
            webClientId: '870445845698-gu25hhdh3fntg8tfe3msqm83bibbdcmf.apps.googleusercontent.com'
          }
        });
      } catch (error) {
        console.error('Failed to initialize social login:', error);
      }
    };
    initializeSocialLogin();
  }, []);

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    try {
      console.log("running SocialLogin.login")
      const result = await SocialLogin.login({
        provider: 'google',
        options: {
          scopes: ['email', 'profile'],
        },
      });
      console.log("Full auth result:", JSON.stringify(result, null, 2));

      await setToken({
        accessToken: result.result.accessToken.token,
        idToken: result.result.idToken,
        // For Google sign-in, we might not have a refresh token, so we'll store the access token as refresh token as well
        refreshToken: result.result.accessToken.token
      });

      await Promise.all([
        Preferences.set({ 
          key: 'username', 
          value: result.result.profile.email 
        }),
        Preferences.set({ 
          key: 'userProfile', 
          value: JSON.stringify({
            name: result.result.profile.name,
            email: result.result.profile.email,
            picture: result.result.profile.imageUrl,
            provider: 'google'
          })
        })
      ]);

      if (onAuthStateChange) {
        onAuthStateChange();
      }
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderButtonContent = () => {
    if (isLoading) {
      return (
        <>
          <svg 
            className="animate-spin h-5 w-5 mr-3" 
            viewBox="0 0 24 24"
          >
            <circle 
              className="opacity-25" 
              cx="12" 
              cy="12" 
              r="10" 
              stroke="currentColor" 
              strokeWidth="4"
              fill="none"
            />
            <path 
              className="opacity-75" 
              fill="currentColor" 
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          <span className="font-medium">Signing in...</span>
        </>
      );
    }

    return (
      <>
        <img 
          src="https://developers.google.com/identity/images/g-logo.png" 
          alt="Google logo" 
          className="w-5 h-5 mr-3"
        />
        <span className="font-medium">Sign in with Google</span>
      </>
    );
  };

  return (
    <button 
      onClick={handleGoogleSignIn}
      disabled={isLoading}
      className={`
        w-full mt-4 p-3 
        flex items-center justify-center 
        border border-gray-300 
        rounded-lg bg-white 
        text-gray-700 
        transition-colors duration-200
        disabled:opacity-50 disabled:cursor-not-allowed
        ${!isLoading && 'hover:bg-gray-50'}
      `}
      aria-busy={isLoading}
    >
      {renderButtonContent()}
    </button>
  );
};

export default GoogleSignInButton;